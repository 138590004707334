<template>
<div :key="componentKey">
  <nav class="sidebar-menu">
    <div class="mt20 mb20 has-text-right mr10">
      <span class="hide-side-bar" @click="hideSidebar">
        <!-- <img src="/static/hide-side-bar.svg" /> -->
        <i class="material-icons-outlined">expand_circle_down</i>
      </span>
    </div>
    <div class="pl10 pr10">
      <div>
        <div class="select-search-type-wrapper">
          <select class="select-search-type" v-model="searchType" @change="setMenuSearchType()" :disabled="isDemo">
            <option value="">Search projects, pages, keywords</option>
            <option value="sites">Projects</option>
            <option value="pages">Pages</option>
            <option value="keywords">Keywords</option>
            <option value="id">Report ID</option>
          </select>
        </div>
      </div>
      <div v-if="searchType == 'id'" class="mt5">
        <div class="field has-addons">
          <div class="control is-expanded">
            <input class="search-box" type="text" v-model="searchReportId" placeholder="Input whole report id">
          </div>
          <div class="control">
            <a class="button is-fullheight search-by-id-btn" @click="searchById">
              Go
            </a>
          </div>
        </div>
      </div>
      <div v-else-if="searchType" class="control mt10">
        <!-- <v-text-field solo rounded hide-details
          @keyup="setMenuSearchCriteria" v-model="searchCriteria" label="Search..."></v-text-field> -->

        <div class="control">
          <input class="search-box"
            type="text" placeholder="Search..." v-model="searchCriteria" @keyup="setMenuSearchCriteria">
        </div>
      </div>
    </div>

    <div class="menu-list-wrapper pl10 pr10 mt20 top-border"
      :class="{ 'bottom-border': !isDemo }"
      style="min-height: 300px; flex-grow: 1;">
      <div class="d-flex align-center mb-3">
        <div class="projects-label">
          <span v-if="showProjectsMenu" class="material-icons"
            @click="() => {
              showProjectsMenu = false;
            }">arrow_drop_down</span>
          <span v-else class="material-icons"
            @click="() => {
              showProjectsMenu = true;
            }">arrow_right</span>
          <router-link :to="`/sites`" class="all-projects">
            <span>Projects</span>
          </router-link>
        </div>
        <router-link v-if="!$store.getters.isDemoUser" :to="`/newsite`">
          <span class="material-icons ml-2 new-project-icon">add</span>
        </router-link>
      </div>
      <div v-if="showProjectsMenu"
        class="project-list-scroll overflow-auto" id="project-list-scroll">
        <ul class="menu-list" v-if="!searchType || ['sites', 'id'].includes(searchType)">
          <li v-for="site in searchedSites" :key="`site-${site.id}`" :id="`site-${site.id}`">
            <div class="d-flex align-center project-item" :class="{ 'active': highlightSelectedItemInSidebar && currentSiteId === site.id && currentPageId === '' }">
              <a :aria-expanded="currentSiteId">
                <i v-if="isExpandedSite(site.id)" class="material-icons" @click="collapseSite(site.id)">keyboard_arrow_down</i>
                <i v-else class="material-icons" @click="expandSite(site.id)">keyboard_arrow_right</i>
                <span class="flex-grow-1 truncate-ellipse-one-line" @click.stop="showTool(site.id)">{{ site.name }}</span>
              </a>
              <div class="action-area">
                <i class="material-icons-outlined tooltip is-tooltip-left" data-tooltip="New page" @click="isDemo ? null : addPage(site)">add</i>
                <i class="material-icons-outlined tooltip is-tooltip-left" data-tooltip="Project Settings" @click="isDemo ? null : enterProjectSetting(site)">settings</i>
                <i class="tooltip is-tooltip-left" :class="{ 'material-icons-outlined': !site.isPinned, 'material-icons profileAnchorColor--text': site.isPinned }"
                  data-tooltip="Pin project" @click="isDemo ? null : pinProjectAction(site.id, !site.isPinned)">
                  push_pin
                </i>
              </div>
            </div>
            <!-- <expanding> -->
              <ul v-show="isExpandedSite(site.id) && (!$store.getters.isDemoUser || isPageCreated())">
                <li v-if="site.pages && site.pages.length==0" class="menu-page">
                  <div class="pl10 is-size-7">
                    No PageRuns
                  </div>
                </li>
                <li v-for="page in (currentSiteId == site.id ? pages : site.pages)" :key="`page-${page.id}`" :id="`page-${page.id}`"
                  class="menu-page" :class="{ 'active': highlightSelectedItemInSidebar && currentPageId === page.id }">
                  <div class="d-flex align-center project-item" :class="{ 'active': highlightSelectedItemInSidebar && currentPageId === page.id && currentPageRunId ===''}">
                    <a
                      :aria-expanded="currentPageId"
                      class="flex-grow-1 min-width-0"
                      @click.stop="showPage(site, page.id)">
                      <i v-if="currentPageId === page.id" class="material-icons">keyboard_arrow_down</i>
                      <i v-else class="material-icons">arrow_forward</i>
                      <span class="is-size-7 truncate-ellipse-one-line">{{ page.name }}</span>
                    </a>
                    <div class="action-area-pages">
                      <!-- <i class="material-icons" @click.stop="rerunAnalysis">autorenew</i> -->
                      <!-- &nbsp; -->
                      <i class="material-icons-outlined tooltip is-tooltip-left" data-tooltip="Page Settings" @click.stop="isDemo ? null : enterPageSetting(page)">settings</i>
                    </div>
                  </div>
                  <!-- <expanding> -->
                    <ul v-if="currentPageId === page.id && (!$store.getters.isDemoUser || isPageRunCreated())">
                      <li v-for="pageRun in currentPageRunsForMenu" :key="`pagerun-${pageRun.id}`" :id="`pagerun-${pageRun.id}`" class="menu-pagerun" :class="{ 'active': highlightSelectedItemInSidebar && currentPageRunId==pageRun.id }">
                        <div class="d-flex align-center">
                          <router-link :to="`/sites/${site.id}/pages/${page.id}/pageruns/${pageRun.id}`" class="flex-grow-1">
                            {{ pageRun.keyword!=null ? pageRun.keyword : page.keyword }} - {{ _.utc(pageRun.createdAt).format('MMM DD, YYYY') }}
                          </router-link>
                          <div v-if="currentPageRunId==pageRun.id" class="flex action-area-for-pagerun">
                            <i class="material-icons tooltip is-tooltip-left" data-tooltip="Rerun" @click="$router.push(`/sites/${site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`)">autorenew</i>
                            &nbsp;
                            <i class="material-icons tooltip is-tooltip-left" data-tooltip="Delete" @click="showDeletePageRunConfirmModal=true">delete</i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  <!-- </expanding> -->
                </li>
              </ul>
            <!-- </expanding> -->
            
          </li>
        </ul>
        <ul class="menu-list" v-if="searchType === 'pages'">
          <li v-for="page in filteredPages" :key="`page-${page.id}`" class="menu-page">
            <router-link :to="`/sites/${page.siteId}/pages/${page.id}`">
              {{page.siteName}} > {{ page.name }}
            </router-link>
          </li>
        </ul>
        <ul class="menu-list" v-if="searchType === 'keywords'">
          <li v-for="page in pagesByKeyword" :key="`page-${page.id}`" class="menu-page">
            <router-link :to="`/sites/${page.siteId}/pages/${page.id}`">
              {{page.siteName}} > {{page.name}} > {{ page.keyword }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!$store.getters.isDemoUser" class="menu-list-wrapper pl10 pr10">
      <div class="d-flex align-center">
        <div class="projects-label">
          <span v-if="showInsightsMenu" class="material-icons"
            @click="() => {
              showInsightsMenu = false;
            }">arrow_drop_down</span>
          <span v-else class="material-icons"
            @click="() => {
              showInsightsMenu = true;
            }">arrow_right</span>
          <span class="all-projects">
            <span>Insights</span>
          </span>
        </div>
      </div>
      <div v-if="showInsightsMenu">
        <div class="reports-left-area pl10 pr10">
          <!-- <recent-credit-log></recent-credit-log> -->
          <div class="link-item">
            <i class="material-icons-outlined">troubleshoot</i>
            <router-link :to="`/useraccount/${user.id}?mainTab=credit-logs`">
              Credits usage history
            </router-link>
          </div>
          <div class="link-item">
            <i class="material-icons-outlined">fact_check</i>
            <router-link
              :to="'/workstation'">
              Workstation
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!$store.getters.isDemoUser">
      <div class="current-credit-box-wrapper pl10 pr10">
        <div class="current-credit-disp-box">
          <div class="d-flex align-center gap15">
            <div>
              <div class="icon-area">
                <i class="material-icons-outlined">savings</i>
              </div>
            </div>
            <div class="credit-status">
              <div>
                {{ accountBalanceLabels[0] }}
              </div>
              <div v-if="user.remainedUniversalCredits > 0">
                Purchased credits: {{ user.remainedUniversalCredits }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <router-link
              v-if="user.activeSubscriber && !isUnlimitedUser"
              :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
              Buy credits
            </router-link>
            <router-link v-else
              class="upgrade-down-btn"
              :to="`/useraccount/${user.id}/purchase`"
              data-tooltip="Click to change service package">
              <span>Manage Subscription</span>
              <span class="current-box">{{ serviceSimplifiedLabel(ownerUser.accountType || ownerUser.ownerAccountType) }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="modal" :class="{'is-active': showDeletePageRunConfirmModal}">
    <div class="modal-background" @click="showDeletePageRunConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div v-if="errorMsg==''" class="is-size-5 mainColor--text mb-3">Do you want to delete this Page run report?</div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon gray2Color--text text-none" @click="showDeletePageRunConfirmModal=false">
              <i class="material-icons">close</i>
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="deletePagerunAction">
              <i class="fa fa-trash mr5"></i>
              <span>Delete</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showDeletePageRunConfirmModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showNotFoundReportModal}">
    <div class="modal-background" @click="showNotFoundReportModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="mainColor--text font-weight-bold is-size-4 mb20">Alert</div>
          <div class="body-font is-size-6 mainColor--text">
            Report with that ID doesn't exist. Please try again.
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              @click="showNotFoundReportModal = false">
              <i class="fa fa-check mr5"></i>
              Ok
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showNotFoundReportModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <project-setting-modal ref="projectSettingModal" />
  <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />
  
  <insufficient-fund-modal ref="insufficientFundModal" />

</div>
</template>

<script>
import moment from 'moment'
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as notificaitons_mixin } from '@/components/mixins/common_notifies';
import ProjectSettingModal from '@/components/ProjectSettingModal.vue'
import PageSettingModal from '@/components/PageSettingModal.vue'
import InsufficientFundModal from '@/components/InsufficientFundModal.vue'
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

export default {
  components: {
    ProjectSettingModal, PageSettingModal, InsufficientFundModal,
  },
  mixins: [ mixin, ServiceMixin, notificaitons_mixin, ],
  data () {
    return {
      searchCriteria: '',
      searchReportId: '',
      searchType: '',
      showProjectsMenu: true,
      showInsightsMenu: true,



      errorMsg: '',
      componentKey: 0,

      showDeletePageRunConfirmModal: false,


      expandedProjectList: [],
      showNotFoundReportModal: false,
    }
  },
  watch: {
    // '$route' (to, from) {
    //   // try {
    //   //   let topPos = document.getElementById(`site-${siteId}`).offsetTop;
    //   //   document.getElementById('project-list-scroll').scrollTop = topPos;
    //   // } catch (err) {
    //   // }
    //   console.log('from', from)
    //   console.log('to', to)
    // },
    '$route': function() {
      console.log('route change is detected')
      let siteId = parseInt(this.$route.params.siteId)
      let pageId = parseInt(this.$route.params.pageId)
      let pageRunId = parseInt(this.$route.params.pageRunId)
      console.log('test of route', siteId, pageId, pageRunId)
      
      if (pageId) {
        this.$store.commit('setCurrentSiteById', { siteId })
        this.$store.commit('setCurrentPageById', { pageId })
        this.expandSite(siteId)
      } else if (siteId) {
        this.$store.commit('setCurrentSiteById', { siteId })
        this.expandSite(siteId)
      }

      // if (pageRunId || pageId || siteId) {
      //   this.$nextTick(() => {
      //     this.menuScrollToProperPosition(siteId, pageId, pageRunId)
      //   })
        
      // }
    },
    'currentPageRunsForMenu': function() {
      // this.forceRerender();
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    _ () {
      return moment
    },
    user () {
      return this.$store.state.user
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.user
    },
    isWatchdogOnlyUser () {
      return this.$store.getters.isWatchdogOnlyUser
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    searchedSites () {
      let criteria = this.searchCriteria.toLowerCase()
      return this.sites
        .filter((x) => {
          if (this.searchType === "sites") {
            return x.name.toLowerCase().indexOf(criteria) !== -1;
          }
          if (!this.searchType || !criteria) {
            return true;
          }
        })
        .slice();
    },
    // sites () { // we don't need this because now we're displaying sites in sidebar with same order as dashboard.
    //   if (this.$store.state.sites && this.$store.state.sites.length) {
    //     return this.$store.getters.getFilteredSites
    //   }
    //   return []
    // },
    currentSite () {
      let site = this.$store.getters.siteById(parseInt(this.$route.params.siteId))
      if (site) {
        return site
      }
      return {}
    },
    filteredPages () {
      const isDemoUser = this.$store.getters.isDemoUser
      const isPageCreated = sessionStorage.getItem(DEMO_SESSION_KEYS.pageCreated)
      if (this.$store.state.sites && this.$store.state.sites.length) {
        const pages = this.$store.getters.getFilteredPages
        if (!isDemoUser) return pages

        if (!isPageCreated) return []

        return pages
      }
      return []
    },
    currentPageRunsForMenu () {
      if (!this.currentPageId) return []
      const pageId = this.currentPageId
      const pageRuns = this.$store.state.pageRuns.filter(pageRun => pageRun.pageId === pageId).slice()
      return pageRuns.sort((a, b) => {
        let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
        if (date1.isBefore(date2)) return 1;
        if (date1.isAfter(date2)) return -1;
        return 0;
      })
    },
    pagesByKeyword () {
      if (this.$store.state.sites && this.$store.state.sites.length) {
        return this.$store.getters.getFilteredPagesByKeyword
      }
      return []
    },
    currentSiteId () {
      return this.$store.state.currentSiteId
    },
    currentPageId () {
      return this.$store.state.currentPageId
    },
    currentPageRunId () {
      const pageRunId = parseInt(this.$route.params.pageRunId)
      if (Number.isNaN(pageRunId)) {
        return ''
      }
      return pageRunId
    },
    accountBalanceLabels() {
      if (!this.user) {
        return ['', '']
      }
      return this.getAccountBalanceLabel(this.ownerUser)
    },
    highlightSelectedItemInSidebar () {
      const excludeRoutes = [
        'GuardDashboard',
      ]
      return !excludeRoutes.includes(this.$route.name)
    },
  },
  methods: {
    forceRerender() {
      console.log('FORCE RERENDERING MENU')
      this.componentKey += 1;  
    },
    isPageCreated() {
      return sessionStorage.getItem(DEMO_SESSION_KEYS.pageCreated)
    },
    isPageRunCreated() {
      return sessionStorage.getItem(DEMO_SESSION_KEYS.runCreated)
    },
    menuScrollToProperPosition (siteId, pageId, pageRunId) {
      let el = document.querySelector('#project-list-scroll')
      let site_dest = document.querySelector(`#site-${siteId}`)
      let page_dest = document.querySelector(`#page-${pageId}`)
      let pagerun_dest = document.querySelector(`#pagerun-${pageRunId}`)
      // console.log(site_dest, page_dest, pagerun_dest)

      let scrollOffset = 0
      if (siteId) { scrollOffset = site_dest.offsetTop; }
      if (pageId) { scrollOffset = page_dest.offsetTop; }
      if (pageRunId) { scrollOffset += pagerun_dest.offsetTop; }

      el.scrollTop = scrollOffset
    },
    logout () {
      this.$store.commit('clearStore')
      this.$router.push('/login')
      this.showMobileNav = false
    },
    addSite () {
      if (this.hasAbilityOfCreateProject()) {
        this.$router.push('/newsite')
      }
    },
    addPage (site1=null) {
      if (this.isWatchdogOnlyUser) {
        this.showCanNotCreatePageBecauseYouWatchdogUserNotification();
        return;
      }
      let siteId = site1 && site1.id ? site1.id : this.$route.params.siteId
      if (this.canCreateNewPage) {
        this.errMsg = ''
        this.$router.push(`/sites/${siteId}/newpage`)
      } else {
        this.$refs.insufficientFundModal.show()
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    showTool (siteId) {
      if (siteId=='') {
        this.$store.commit('setCurrentSiteById', { siteId: '' })
        this.$router.push(`/sites`)
      } else {
        if (this.currentSiteId==siteId && this.isExpandedSite(siteId)) {
          this.collapseSite(siteId)
        } else {
          this.expandSite(siteId)
        }
        this.$router.push(`/sites/${siteId}/tools`)
      }
    },
    showSite (siteId) {
      if (siteId=='') {
        this.$store.commit('setCurrentSiteById', { siteId: '' })
        this.$router.push(`/sites`)
      } else {
        if (this.currentSiteId==siteId && this.isExpandedSite(siteId)) {
          this.collapseSite(siteId)
        } else {
          this.expandSite(siteId)
        }
        this.$router.push(`/sites/${siteId}/pages`)
      }
    },
    showPage (site, pageId) {
      if ((this.currentPageId===pageId && !this.currentPageRunId) || pageId=='') {
        this.$store.commit('setCurrentPageById', { pageId: '' })
        this.$router.push(`/sites/${this.$route.params.siteId}/pages`)
      } else {
        let siteId = site.id
        this.$router.push(`/sites/${siteId}/pages/${pageId}`)
        this.$store.commit('setCurrentPageById', { pageId })
      }
    },
    setMenuSearchCriteria(event) {
      this.$store.dispatch('setMenuSearchCriteria', this.searchCriteria)
      return true
    },
    setMenuSearchType() {
      this.$store.dispatch('setMenuSearchType', this.searchType)
    },
    enterProjectSetting (site) {
      this.$refs.projectSettingModal.show(site) 
    },
    enterPageSetting(page) {
      this.$refs.pageSettingModal.show(page)
    },
    deletePagerunAction () {
      this.$store.commit('showLoading')

      let siteId = this.currentSiteId
      let pageId = this.currentPageId
      let pageRunId = this.currentPageRunId
      return this.$store.dispatch('removePageRun', {
          pageRunId,
          siteId,
          pageId
        })
        .then((response) =>  this.$store.dispatch('loadPageRuns', this.$route.params))
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setPageRuns', response.data.pageRuns)
          }
          this.showDeletePageRunConfirmModal = false
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    pinProjectAction (siteId, isPinned) {
      this.$store.commit('showLoading')
      this.$store.dispatch('saveProjectSettings', {
        siteId,
        changePinOnly: true,
        isPinned: isPinned ? 1 : 0,
      })
      .then((res) => {
        if (res.data && res.data.status=='SUCCESS') {
          this.$store.commit('setSite', res.data.site)
        } else {
          let error = res.data.error ? res.data.error : ''
          window.alert('Can not update project settings.\n' + error)
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },

    hideSidebar () {
      this.$store.commit('setIsUserClosedSideBar', true)
      this.$store.commit('setHideSideBar', true)
    },
    isExpandedSite(siteId) {
      return this.expandedProjectList.includes(siteId)
    },
    expandSite(siteId) {
      if (!this.expandedProjectList.includes(siteId)) {
        this.expandedProjectList.push(siteId)
      }
    },
    collapseSite(siteId) {
      let idx = this.expandedProjectList.indexOf(siteId)
      console.log(idx)
      if (idx>=0) {
        this.expandedProjectList.splice(idx, 1)
      }
    },
    searchById () {
      if (!this.searchReportId) {
        window.alert('You must input report id to search')
        return
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('loadPageRunsBySearchReportId', this.searchReportId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log('PAGERUNS', response.data.pageRuns)
          let page = response.data.page
          let p = response.data.pageRuns.find(item => item.id == this.searchReportId)
          this.$store.commit('setPageRuns', response.data.pageRuns)
          
          let link = `/sites/${page.siteId}/pages/${page.id}/pageruns/${p.id}`
          this.$router.push(link)
        } else {
          this.showNotFoundReportModal = true
        }
      })
      .catch(error => {
        console.log('Unexpected error in finding by report id')
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    }
  },
}
</script>

<style lang="scss">
$border: 2px solid rgba(128, 128, 128, 0.2);

$bg-color: #012323;
$comp-bg: #25464E;
$main-btn-color: #FF5700;

.new-project-link {
  i {
    font-size: 20px;
    color: var(--v-profileAnchorColor-base);
  }
  span {
    font-size: 13.75px;
    color: var(--v-mainColor-base);
  }
}

.sidebar-menu {
  height: 100vh !important;
  position: absolute;
  min-width: 300px;
  max-width: 300px;
  z-index: 1;
  font-size: 0.8rem;
  top: 0;
  left: 0;
  background: $bg-color; // to make it always this color in all themes
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  
  .hide-side-bar {
    font-weight: bold;
    cursor: pointer;
    & > img {
      width: 20px;
    }
    i {
      font-size: 1.5rem;
      color: white;
      transform: rotate(90deg);
    }
  }

  .select-search-type-wrapper {
    position: relative;
    border-radius: 5px;
    // border: 1px solid var(--v-grayColor-base);
    overflow: hidden;
    .select-search-type {
      width: 100%;
      height: 2.5rem;
      border: none;
      text-indent: 1rem;
      font-size: 11px;
      padding-left: 20px;
      color: white;
      background: $comp-bg;
      &:focus {
        box-shadow: none;
      }
    }
    &:before {
      position: absolute;
      font-family: 'Material Icons';
      content: '\e8b6';
      font-size: 1rem;
      color: var(--v-grayColor-base);
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      position: absolute;
      font-family: 'Material Icons';
      content: '\e5cf';
      font-size: 1rem;
      color: var(--v-grayColor-base);
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .search-box {
    font-size: 11px;
    background: $comp-bg;
    color: white;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 10px;
    &::placeholder {
      color: white;
    }
  }
  .search-by-id-btn {
    background: $main-btn-color;
    border-color: $comp-bg;
    color: white;
  }

  .all-projects {
    color: white;
    font-size: 1rem;
    font-weight: 700;
  }
  .new-project-icon {
    color: white;
    font-size: 1.3rem;
    background: $main-btn-color;
    border-radius: 50%;
    font-size: 1rem;
    padding: 2px 2px;
  }
  .menu-list-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    /* overflow: hidden; */
    &.top-border {
      border-top: 1px solid $comp-bg;
    }
    &.bottom-border {
      border-bottom: 1px solid $comp-bg;
    }
    $SUB_MENU_COLOR: #AAAAAA;

    .projects-label {
      display: flex;
      align-items: center;
      color: var(--v-whiteColor-base);
      cursor: pointer;
      a {
        font-size: 1rem;
        font-weight: 800;
      }
    }

    .project-list-scroll {
      position: relative;
      flex-grow: 1;
      max-height: 100%;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: $comp-bg;
      }
      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 3px;
      }
    }

    .menu-list {
      padding-left: 0px !important;
      margin-right: 2px;
      & li a {  // this is project 
        flex-grow: 1;
        min-width: 0;
        
        display: flex;
        align-items: center;
        font-weight: bold;
        color: white;
      }
      a:hover {
        background: transparent;
      }

      .project-item {
        position: relative;
        padding-left: 5px;
        a {
          color: white;
          padding: 5px 5px 5px 5px;
          i {
            font-size: 0.8rem;
            margin-right: 5px;
          }
          span {
            font-size: 13px; // site font
          }
        }
        &.active {
          background: #094141;
          border-radius: 3px;
          overflow: hidden;
          &:after {
            position: absolute;
            content: '';
            background: $main-btn-color;
            width: 4px;
            height: 100%;
            left: 0;
            top: 0;        
          }
        }
        &:hover {
          border-radius: 3px;
          background: rgba(#094141, 0.6);
        }
      }

      .action-area {
        color: white;
        padding-right: 10px;
        display: flex;
        gap: 5px;
        i {
          font-size: 0.85rem;
          cursor: pointer;
        }
      }
      li ul {
        border-left: none;
        margin: 0;
        padding-left: 7px;
      }

      a.is-active {
        background: none;
        color: $SUB_MENU_COLOR;
      }
      .menu-page {
        position: relative;
        padding-left: 0px;
        font-size: 0.7rem;
        color: white;
        i { font-size: 1.35rem; }
        /* &.active {
          background: var(--v-link7Color-base);
        } */
        ul {
          padding-left: 25px;
        }
        .action-area-pages {
          color: var(--v-darkGrayColor-base);
          padding-left: 10px;
          padding-right: 10px;
          i {
            font-size: 0.9rem;
            cursor: pointer;
          }
        }
      }

      .menu-pagerun {
        position: relative;
        padding-left: 18px;
        font-size: 0.65rem;
        a {
          color: $SUB_MENU_COLOR;
          padding: 3px 0px 3px 0px;
        }
        &:before {
          content: '';
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          top: 50%;
          left: 7px;
          background: $SUB_MENU_COLOR;
          transform: translateY(-50%);
        }
        &.active:before {
          background: $main-btn-color;
        }

        &.active {
          background: $comp-bg;
          &:after {
            position: absolute;
            content: '';
            background: $main-btn-color;
            border-radius: 3px;
            width: 4px;
            height: 100%;
            left: 0;
            top: 0;        
          }
        }

        .action-area-for-pagerun {
          color: $main-btn-color;
          padding-left: 10px;
          padding-right: 10px;
          i {
            font-size: 0.9rem;
            cursor: pointer;
          }
        }
      }
    }
  }
  .manage-profile-button {
    i { color: #00cd3b; }
    span {
      margin-left: 10px;
      color: var(--v-grayColor-base);
    }
  }
  .logout {
    color: var(--v-grayColor-base);
  }
  .reports-left-area {
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link-item {
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        font-size: 1.3rem;
        color: #578986;
      }
      a {
        color: white;
        font-weight: 700;
      }
    }  
  }
  .upgrade-down-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--v-whiteColor-base);
    & > span:first-child {
      text-decoration: underline;
      font-weight: 700;
    }
    .current-box {
      background: var(--v-darkBlue1Color-base);
      color: var(--v-whiteColor-base);
      font-size: 0.7rem;
      font-weight: 700;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
  .current-credit-box-wrapper {
    padding-top: 0px;
    padding-bottom: 10px;

    .current-credit-disp-box {
      padding: 10px 12px;
      border-radius: 5px;
      background: $comp-bg;
      color: white;

      .icon-area {
        background: $main-btn-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border-radius: 5px;
        i {
          font-size: 1.4rem;
        }
      }
      .credit-status {
        
        & div {
          font-weight: 700;
          font-size: 0.8rem; 
        }
      }
    }
  }
}

</style>
