<template>
  <section class="section">
    <div class="px-2">
      <div class="columns is-desktop">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="filterField">
                  <option value="email">Email</option>
                  <option value="stripeId">Stripe customer id</option>
                  <option value="companyName">Company Name</option>
                  <option value="vatID">VAT ID</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <input type="text" v-on:keyup.enter="onFilter" class="input" placeholder="Search" v-model="searchText">
          </div>
        </div>
        <div class="column is-2">
          <date-picker v-model="dates" range></date-picker>
        </div>
        <div class="column is-4">
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text ml-2 mr-1"
            @click="onFilter">Search</v-btn>
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-1"
            @click="exportCsv">Export to CSV</v-btn>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="totalPaid" class="mb-4 text-right mainColor--text font-weight-bold" style="font-size: 1rem;">
        Total paid: ${{ totalPaid }}.00
      </div>
      
      <b-table
        :data="users"
        ref="userTable"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        :current-page="currentPage"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      >
        <template slot-scope="props">
          <b-table-column field="id" label="ID" sortable numeric>{{ props.row.id }}</b-table-column>
          <b-table-column field="email" label="Email" sortable>{{ props.row.email }}</b-table-column>
          <b-table-column field="createdAt" label="Joined Date" sortable>{{ toLocalDateString(props.row.createdAt) }}</b-table-column>
          <b-table-column field="accountType" label="Type">{{ shortenServiceLabel(serviceLabel(props.row.accountType)) }}</b-table-column>
          <b-table-column label="Cancelled on">
            {{ userLastCancelledOn(props.row) }}
          </b-table-column>
          <b-table-column field="activeSubscription" label="Active">
            {{ isFreeUser(props.row) ? 'Free user' : ( props.row.activeSubscriber || props.row.activeSubscription ? 'Yes' : 'No' ) }}
          </b-table-column>
          <b-table-column label="Monthly Subscription amount">{{ formatCurrency(monthlySubscriptionAmount(props.row)) }}</b-table-column>
          <b-table-column label="Total amount paid Lifetime">{{ formatCurrency(totalAmountPaidLifetime(props.row)) }}</b-table-column>
        </template>
      </b-table>
    </div>

  </section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import { mixin as ExportMixin } from '@/components/export_mixin'
// import { mixin as BaseMixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { toLocalDateString, getPureServicePkgName, formatCurrency } from '@/utils'
import {
  freePlans,
  additionalSeatPlans,
  whiteGlovePlans
} from "@/components/plans_constants"

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import service_package_constants from "@/components/service_package_constants"


import { DEV_MODE_TOOLS } from "@/consts/ui_consts.js";


const now = new Date()

export default {
  mixins: [ExportMixin, ServiceMixin],
  components: {DatePicker},
  data() {
    return {
      dates: [moment().subtract(1, 'months').toDate(), moment().toDate()],
      totalPaid: null,
      users: [],
      ready: true,
      progress: 0,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 10,
      totalRows: 0,
      editting: false,
      edittingRow: {},
      currentEmail: '',
      currentPayPalEmail: '',
      currentAccountType: '',
      currentAccountCoupon: '',
      currentAffiliateCredits: 0,
      currentIsAffiliate: false,
      currentIsNoDomainRestrictionOnFreeTrial: false,
      currentAllowWhiteGlovePurchase: false,
      currentAllowMissionControlAsSpecialSubAccount: false,
      isAffiliateChange: false,
      currentAffiliateExpiry: new Date(now.getFullYear() + 99, now.getMonth(), now.getDate()),
      currentAffiliateId: null,
      currentMonthlyCreditsFree: 0,
      currentRemainedMonthlyCreditsMonths: 0,
      currentIsBlockedByAdmin: false,
      currentGrantedDevModeTools: [],

      searchText: '',
      filterField: 'email',
      perPageData: [],
      isSimple: false,
      isRounded: false,
      currentPage: 1,
      total: 0,
      isLoading: false, // this is not used because store loading is used so don't need
      createPurchase: false,
      currentAffiliatePercentage: 0.25,
      currentAffiliateCustomCode: '',
      currentAffiliateCoupons: [],
      adminCommentText: '',
      errorUpdatingUserMsg: '',

      purchaseDataForSubscriptionFind: [],
      selectedUser: null,
      selectedUserActiveSubscription: null,
      
      showDownloadByCancellationDateModal: false,
      downloadDateRange: [moment().subtract(3, 'months').toDate(), moment().toDate()],
    }
  },
  computed: {
    DEV_MODE_TOOLS () {
      return DEV_MODE_TOOLS
    },
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    _() {
      return _
    },
  },
  methods: {
    formatCurrency: formatCurrency,
    isFreeUser (user) {
      if (!user) return false;
      if (freePlans.includes(user.accountType)) {
        return true;
      }
      if (user.isFreePeriodUser) {
        return true;
      }
      return false;
    },
    amountPaidPerMonth(user) {
      return service_package_constants?.[getPureServicePkgName(user?.lastActiveSubscription?.servicePackage)]?.price
    },
    monthlySubscriptionAmount(user) {
      if (!whiteGlovePlans.includes(getPureServicePkgName(user?.lastActiveSubscription?.servicePackage))) {
        return undefined
      }
      return user?.lastActiveSubscription?.credits
    },
    totalAmountPaidLifetime(user) {
      // NOTE(Hichem): renewed purchases will keep the same purchase record/id and have multiple invoices, one for each billing period
      // This is why the following gets the purchases, then for each purchase gets all its associated invoices

      const purchase_ids = (user?.purchases || []).filter(purchase => {
        return whiteGlovePlans.includes(getPureServicePkgName(purchase?.servicePackage))
      }).map(purchase => purchase.id)

      const invoices = (user?.invoices || []).filter(invoice => {
        return purchase_ids.includes(invoice.purchaseId) && invoice.status === 'paid'
      })

      return invoices.map(invoice => invoice.total || 0).reduce((partialSum, a) => partialSum + a, 0)
    },
    userLastCancelledOn(user) {
      if (!user || user.activeSubscriber) {
        return ''
      }
      try {
        let cancelledOn = ''
        for (let p of user.purchases) {
          if (!freePlans.includes(p.servicePackage) && p.subscriptionId && !additionalSeatPlans.includes(getPureServicePkgName(p.servicePackage))) {
            if (p.cancelledOn) {
              cancelledOn = p.cancelledOn
              break
            }
          }
        }
        return cancelledOn
      } catch (e) { }
      return 'null';
    },
    onFilter () {
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onPageChange (page) {
      this.page = page
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    loadData () {
      this.$store.commit('showLoading')

      let startDate = this.dates[0]?moment(this.dates[0]).startOf('day').toDate():null;
      let endDate = this.dates[1]?moment(this.dates[1]).endOf('day').toDate():null;

      let params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.page}`,
        `perPage=${this.perPage}`,
        `filterField=${this.filterField}`,
        `filterText=${this.searchText.replace(/\s+/g, '')}`, // remove all white space before searching. case will be ignored on server side
        `accountType=white_glove`,
      ]

      if (startDate) {
        params.push(`startDate=${startDate.getTime()}`)
      }
      if (endDate) {
        params.push(`endDate=${endDate.getTime()}`)
      }

      params = params.join('&')

      return this.$store.dispatch('loadUsersForAdmin', { params })
        .then((response) => {
          const { users, totalUsers, totalPaid } = response.data
          this.users = users
          this.total = totalUsers
          this.totalPaid = totalPaid
        })
        .catch((err) => {
          console.log('Error fetching admin users ', err)
        })
        .finally(() => {
          this.loading = false
          this.$store.commit('hideLoading')
        })
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    setCurrentAccountCoupon (event) {
      this.currentAccountCoupon = event.target.value
      this.createPurchase = true
    },
    exportCsv () {

      this.$store.commit('showLoading')
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=1`,
        `perPage=${this.total}`,
        `filterField=${this.filterField}`,
        `filterText=${this.searchText.replace(/\s+/g, '')}`, // remove all white space before searching. case will be ignored on server side
        `accountType=white_glove`,
      ].join('&')
      return this.$store.dispatch('loadUsersForAdmin', { params })
        .then((response) => {
          let csvData = [[
            'ID',
            'Email',
            'Joined Date',
            'Account Type',
            'Cancelled on',
            'Active',
            'Monthly Subscription amount',
            'Total amount paid Lifetime',
          ].join(',')]
          response.data.users.forEach((u) => {
            csvData.push([
              u.id,
              u.email,
              moment(u.createdAt).format(),
              this.shortenServiceLabel(this.serviceLabel(u.accountType)),
              this.userLastCancelledOn(u),
              this.isFreeUser(u) ? 'Free user' : ( u.activeSubscriber || u.activeSubscription ? 'Yes' : 'No' ),
              this.monthlySubscriptionAmount(u),
              this.totalAmountPaidLifetime(u)
            ].join(','))
          })
          const fileName = `WhiteGlove_Users_${now.getUTCFullYear()}_${(now.getUTCMonth()+1)}_${now.getUTCDate()}_Page${this.page}.csv`
          this.exportToCSV(csvData.join('\n'), fileName)
        })
        .catch((err) => {
          console.log('Error fetching admin users ', err)
        })
        .finally(() => {
          this.loading = false
          this.$store.commit('hideLoading')
        })
    },
    shortenServiceLabel(label) {
      if (label === 'White Glove Plan Unlimited Monthly (5 Sub Accounts / Available to add more seats)') {
        return 'White Glove Plan'
      }
      return label
    },
    onClickPage(pageNum) {
      this.current = pageNum;
      this.perPageData = this.$store.state.allUsers.slice(this.perPage * (pageNum - 1), pageNum * this.perPage)
    },
    changeSearchText() {
      if (!this.searchText) {
        this.current = 1;
        this.total = this.$store.state.allUsers.length;
        this.perPageData =  this.$store.state.allUsers.slice(0, this.perPage);

      } else {
        const searchData = this.users.filter((u) => {
          const acctType = u.accountType === 'trial' ? 'Trial' : u.accountType === 'gold' ? 'Pay As You Go' : 'Unlimited'
          const toSearch = `${acctType} ${u.email} ${u.accountBalance} ${u.accountCoupon}`.toLowerCase()
          return toSearch.indexOf(this.searchText.toLowerCase()) > -1
        });
        this.current = 1;
        this.total = Math.ceil(searchData.length / this.perPage);
        this.perPageData = searchData.slice(0, this.perPage);
      }
    },
  },
  beforeMount () {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.table-mobile-sort {
  display: none;
}

</style>
