
import {
  DEFAULT_PREFIX,
  agencyMonthlyPlans,
  agencyYearlyPlans,
  agencyPlans,
  unlimitedServices,
  additionalSeatPlans,
  oldPlans,
  watchdogOnlyServices,
  CURRENT_YEARLY_PLANS,
  PRICE_OF_EACH_VERSIONING,
} from "@/components/plans_constants"

import ALL_PLANS_OBJ from "@/components/service_package_constants"
import { getPureServicePkgName, serviceLabel } from '@/utils'

export const mixin = {
  data() {
    return {
      showReactivateSubscription: false,
      purchaseAmt: 10,
      amountDue: -1,
      serviceDescription: '',
      selectedServicePkg: '',
      paymentService: '',
      promoCode: '',
      periodFreeCouponApplied: false,
      appliedCoupon: null,
      SERVICE_PKG_A_BASE_PRICE: 10,
      SERVICE_PKG_B_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_B2'].price,
      SERVICE_PKG_C_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_C'].price,
      SERVICE_PKG_D_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_D'].price,
      SERVICE_PKG_E_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_E'].price,
      SERVICE_PKG_F_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_F'].price,
      SERVICE_PKG_G_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_G'].price,
      SERVICE_PKG_H_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_H'].price,
      SERVICE_PKG_I_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_I'].price,
      SERVICE_PKG_J_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_J'].price,
      SERVICE_PKG_K_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_K'].price,
      SERVICE_PKG_AA_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_AA'].price,
      SERVICE_PKG_BB_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_BB'].price,
      SERVICE_PKG_CC_BASE_PRICE: ALL_PLANS_OBJ['SERVICE_PKG_CC'].price,

      SERVICE_PKG_L_BASE_PRICE: 174,
      SERVICE_PKG_M_BASE_PRICE: 1740,

      EXTRA_SEAT_AA_BASE_PRICE: 12,
      EXTRA_SEAT_BB_BASE_PRICE: 120,
      EXTRA_SEAT_CC_BASE_PRICE: 12,

      serviceCoupons: [],
      serviceCouponsValid: null,  /* can be null, true, false (if error) */
      serviceCouponsMessage: null,
      showAgencyPlanOptions: false,
      selectedAgencyPlanType: 1,
      showYearlyForSingleUserPlan: false,

      currentTabLabel: 'Single user plans',
      isEnteringServicePurcasePage: false,
      payViaInvoice: false,
    }
  },
  watch:  {
    'showAgencyPlanOptions': function(newVal, oldVal) {
      if (newVal) {
        this.currentTabLabel = 'Team plans'
      } else {
        this.currentTabLabel = 'Single user plans'
      }

    },
    'selectedAgencyPlanType': function (newVal, oldVal) {
      if (this.isAgencyPlanSelected('monthly')) {
        this.updateSelectedServicePkgForAgency('monthly')
      } else if (this.isAgencyPlanSelected('yearly')) {
        this.updateSelectedServicePkgForAgency('yearly')
      } else if (this.isAgencyPlanSelected('monthly_white_glove')) {
        this.updateSelectedServicePkgForAgency('monthly_white_glove')
      }
    },
    'serviceCoupons': function(newVal, oldVal) {
      this.updateSelectedServicePkg(this.selectedServicePkg)
    }
  },
  methods: {
    calcDiscountedPrice (originPrice, coupon) {
      if (coupon.couponType == 'Percent Off') {
        return (100 - coupon.amountOff) / 100 * originPrice
      } else {
        return originPrice - coupon.amountOff
      }
    },
    getPureServicePkgName(arg_serviceName) {
      return getPureServicePkgName((arg_serviceName))
    },
    getPurchasePrice (arg_servicePkg) {
      let servicePkg = this.getPureServicePkgName(arg_servicePkg)
      let purchasePrice
      switch(servicePkg) {
        case 'SERVICE_PKG_A':
        case 'SERVICE_PKG_B':
        case 'SERVICE_PKG_B2':
          purchasePrice = this.servicePkgBPrice
          break
        case 'SERVICE_PKG_C':
          purchasePrice = this.servicePkgCPrice
          break
        case 'SERVICE_PKG_D':
          purchasePrice = this.servicePkgDPrice
          break
        case 'SERVICE_PKG_E':
          purchasePrice = this.servicePkgEPrice
          break
        case 'SERVICE_PKG_F':
          purchasePrice = this.servicePkgFPrice
          break
        case 'SERVICE_PKG_G':
          purchasePrice = this.servicePkgGPrice
          break
        case 'SERVICE_PKG_H':
          purchasePrice = this.servicePkgHPrice
          break
        case 'SERVICE_PKG_I':
          purchasePrice = this.servicePkgIPrice
          break
        case 'SERVICE_PKG_J':
          purchasePrice = this.servicePkgJPrice
          break
        case 'SERVICE_PKG_K':
          purchasePrice = this.servicePkgKPrice
          break
        case 'SERVICE_PKG_L':
          purchasePrice = this.servicePkgLPrice
          break
        case 'SERVICE_PKG_M':
          purchasePrice = this.servicePkgMPrice
          break
        case 'SERVICE_PKG_AA':
          purchasePrice = this.servicePkgAAPrice
          break
        case 'SERVICE_PKG_BB':
          purchasePrice = this.servicePkgBBPrice
          break;
        case 'SERVICE_PKG_CC':
          purchasePrice = this.servicePkgCCPrice
          break
        case 'EXTRA_SEAT_AA':
          purchasePrice = this.extraSeatAAPrice
          break;
        case 'EXTRA_SEAT_BB':
          purchasePrice = this.extraSeatBBPrice
          break;
        case 'EXTRA_SEAT_CC':
          purchasePrice = this.extraSeatCCPrice
          break;
      }
      return purchasePrice
    },
    fetchValidatedCoupons (allowCreditCoupon=false, isRegisterPage=false) {
      this.periodFreeCouponApplied = false;
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchValidatedCoupons', {
        promoCode: this.promoCode.trim(), 
        isRegisterPage: isRegisterPage ? 1 : 0,
      })
      .then((response) => {

        if (response.data && response.data.status == 'FAILURE' ) {
          this.serviceCouponsValid = false;
          this.serviceCouponsMessage = response.data.errorMsg || 'Invalid coupon';
          return []
        }
        let allServiceCoupons = response.data.coupons || []

        if (allServiceCoupons.length == 1) {
          const coupon = allServiceCoupons[0];
          if (coupon.couponType == 'Period Free' && coupon.freeDays > 0) {
            if (this.selectedServicePkg && additionalSeatPlans.includes(this.selectedServicePkg)) {
              this.serviceCouponsValid = false;
              this.serviceCouponsMessage = 'Invalid coupon.'
              allServiceCoupons = []
            } else if (coupon.exceeded) {
              this.serviceCouponsValid = false;
              this.serviceCouponsMessage = "Promo code's use limit has been exceeded";
              allServiceCoupons = []
            } else {
              this.serviceCouponsValid = true;
              this.periodFreeCouponApplied = true;
              this.serviceCouponsMessage = 'Free Period coupon is applied.'
            }
            return allServiceCoupons
          }

          if (allowCreditCoupon && coupon.couponType == 'Credits') {
            if (coupon.exceeded) {
              this.serviceCouponsValid = false;
              this.serviceCouponsMessage = "Promo code's use limit has been exceeded";
              allServiceCoupons = []
            }
            return allServiceCoupons              
          }
        }


        let isCouponForDifferentProduct = false
        let couponProduct = allServiceCoupons.length > 0 ? allServiceCoupons[0].product : ''
        

        let serviceCoupons = []
        let error = null;
        
        if (this.selectedServicePkg) {
          serviceCoupons = allServiceCoupons.filter(c => c.product == this.selectedServicePkg || c.product == 'ANY')
          if (serviceCoupons.length == 0) {
            isCouponForDifferentProduct = true
          }
        } else {
          error = 'Please select plan first'
          serviceCoupons = []
        }
        console.log('error??', error)
        
        if (serviceCoupons.length > 0) {
          couponProduct = serviceCoupons[0].product
        }
        this.serviceCoupons = serviceCoupons;
        /* Are there valid promo codes? */
        if (!error) {
          const hasCoupons = (this.serviceCoupons.filter(c => !c.exceeded).length > 0);
          if (response.data.status === 'SUCCESS') {
            const { type } =  response.data
            if (type === 'UNKNOWN') {
              error = 'invalid promo code';
            } else if (type === 'AFFILIATE' || type === 'AFFILIATE_COUPONS') {
              console.log('This is just affiliate ')
            } else if (isCouponForDifferentProduct) {
              // this might be for right plan but old pricing coupon. 
              // need to check about it.
              if (this.isCouponForRightPlanButOldCoupon(couponProduct)) {
                error = `This is an old coupon and can't be used anymore.`
              } else {
                error = `This promo code is for ${this.serviceLabel(couponProduct)}.  Please select that plan first and then apply this promo code`
              }
            } else if (type !== 'AFFILIATE' && !hasCoupons) {
              error = "Promo code's use limit has been exceeded";
            }
          } else {
            error = 'invalid promo code';
          }
        }

        this.serviceCouponsValid = (error === null);
        if (error) {
          this.serviceCouponsMessage = error;
        } else {
          this.serviceCouponsMessage = 'Promo code has been applied'

          // special offer operation
          if (this.promoCode && this.promoCode.startsWith('BF2019')) {
            this.showSinglePlan = false
            this.updateSelectedServicePkg(`${DEFAULT_PREFIX}SERVICE_PKG_I`)
          }

        }
        return serviceCoupons
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    updateSelectedServicePkg(arg_servicePkg) {
      this.periodFreeCouponApplied = false;
      let servicePkg = this.getPureServicePkgName(arg_servicePkg)

      this.purchaseAmt = this.getPurchasePrice(arg_servicePkg)
      switch(servicePkg) {
        case 'SERVICE_PKG_A':
          this.serviceDescription = 'Page Optimizer Starter'
          break
        case 'SERVICE_PKG_B':
        case 'SERVICE_PKG_B2':
          this.serviceDescription = 'Page Optimizer Basic'
          break
        case 'SERVICE_PKG_C':
          this.serviceDescription = 'Page Optimizer Premium'
          break
        case 'SERVICE_PKG_D':
          this.serviceDescription = 'Page Optimizer Unlimited'
          this.showYearlyForSingleUserPlan = false
          break
        case 'SERVICE_PKG_E':
          this.serviceDescription = 'Page Optimizer Unlimited'
          this.showYearlyForSingleUserPlan = true
          break
        case 'SERVICE_PKG_F':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Monthly ( 5 Sub Accounts )'
          this.selectedAgencyPlanType = 1
          break
        case 'SERVICE_PKG_G':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Monthly ( 10 Sub Accounts )'
          this.selectedAgencyPlanType = 2
          break
        case 'SERVICE_PKG_H':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Monthly ( 20 Sub Accounts )'
          this.selectedAgencyPlanType = 3
          break
        case 'SERVICE_PKG_I':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Yearly ( 5 Sub Accounts )'
          this.selectedAgencyPlanType = 1
          break
        case 'SERVICE_PKG_J':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Yearly ( 10 Sub Accounts )'
          this.selectedAgencyPlanType = 2
          break
        case 'SERVICE_PKG_K':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Yearly ( 20 Sub Accounts )'
          this.selectedAgencyPlanType = 3
          break
        case 'SERVICE_PKG_L':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Monthly ( 100 Sub Accounts )'
          this.selectedAgencyPlanType = 4
          break
        case 'SERVICE_PKG_M':
          this.serviceDescription = 'PageOptimizer Unlimited Agency Yearly ( 100 Sub Accounts )'
          this.selectedAgencyPlanType = 4
          break
        case 'SERVICE_PKG_AA':
          this.serviceDescription = 'PageOptimizer Unlimited Teams Plan Monthly ( 5 Sub Accounts )'
          break
        case 'SERVICE_PKG_BB':
          this.serviceDescription = 'PageOptimizer Unlimited Teams Plan Yearly ( 5 Sub Accounts )'
          break
        case 'SERVICE_PKG_CC':
          this.serviceDescription = 'PageOptimizer Unlimited White Glove Plan Monthly ( 5 Sub Accounts )'
          break
        case 'EXTRA_SEAT_AA':
          this.serviceDescription = 'PageOptimizer Teams Plan Seat Monthly'
          break
        case 'EXTRA_SEAT_BB':
          this.serviceDescription = 'PageOptimizer Teams Plan Seat Yearly'
          break
        case 'EXTRA_SEAT_CC':
          this.serviceDescription = 'PageOptimizer White Glove Plan Seat Yearly'
          break

        default:
          console.log('I did not meet appropriate case')
          break;
      }
      this.selectedServicePkg = arg_servicePkg
      console.log(this.selectedAgencyPlanType, arg_servicePkg, this.selectedServicePkg)
    },
    updateSelectedServicePkgForAgency (chargeType) {
      let servicePkg = ''
      let prefix = DEFAULT_PREFIX
      if (chargeType == 'monthly') {
        servicePkg = `${prefix}SERVICE_PKG_AA`
        // switch (this.selectedAgencyPlanType) {
        //   case 1:
        //     servicePkg = `${prefix}SERVICE_PKG_F`
        //     break
        //   case 2:
        //     servicePkg = `${prefix}SERVICE_PKG_G`
        //     break
        //   case 3:
        //     servicePkg = `${prefix}SERVICE_PKG_H`
        //     break;
        //   case 4:
        //     servicePkg = `SERVICE_PKG_L`
        //     break
        // }
      } else if (chargeType == 'yearly') {
        servicePkg = `${prefix}SERVICE_PKG_BB`
        // switch (this.selectedAgencyPlanType) {
        //   case 1:
        //     servicePkg = `${prefix}SERVICE_PKG_I`
        //     break
        //   case 2:
        //     servicePkg = `${prefix}SERVICE_PKG_J`
        //     break
        //   case 3:
        //     servicePkg = `${prefix}SERVICE_PKG_K`
        //     break
        //   case 4:
        //     servicePkg = `SERVICE_PKG_M`
        //     break
        // }
      } else if (chargeType == 'monthly_white_glove') {
        servicePkg = `${prefix}SERVICE_PKG_CC`
      }
      console.log(servicePkg)
      this.updateSelectedServicePkg(servicePkg)
    },
    isSubscriptionService (serviceName) {
      return this.serviceLabel(serviceName) !== 'Credits'
    },
    getRunBalance (user) {
      const accountType = this.serviceLabel(user.accountType)
      if (accountType === 'Unlimited') {
        return accountType
      } else {
        return accountType === 'WatchDog Only' ? user.watchDogBalance : user.monthlyCredits
      }
    },
    // getFullMonthlyCreditAmount (pureAccountType) {
    //   switch (pureAccountType) {
    //     case 'SERVICE_PKG_A':
    //       return 5
    //     case 'SERVICE_PKG_B':
    //     case 'SERVICE_PKG_B2':
    //       return 12
    //     case 'SERVICE_PKG_C':
    //       return 25
    //     default:
    //       return 0
    //   }
    // },
    getAccountBalanceLabel (user) {
      let pureAccountType = this.getPureServicePkgName(user.accountType)

      // let nlpEatExplanationText = `( NLP: ${user.remainedGoogleNlpUsage}, EEAT: ${user.remainedEATUsage} )`
      let monthlyCreditsText = `Monthly credits: ${ user.monthlyCredits }`
      let purchasedCreditsText = `Purchased credits: ${ user.remainedUniversalCredits }`

      return [monthlyCreditsText, purchasedCreditsText]

      // if (pureAccountType == 'trial' || pureAccountType == 'gold') {
      //   return [monthlyCreditsText, purchasedCreditsText]
      // }
      // if (pureAccountType == 'end_user' || unlimitedServices.includes(pureAccountType)) {
      //   return [monthlyCreditsText, purchasedCreditsText]
      // }
      // if (pureAccountType == 'sub_account') {
      //   return [monthlyCreditsText, purchasedCreditsText]
      // }

      // if (watchdogOnlyServices.includes(pureAccountType )) {
      //   return [monthlyCreditsText, purchasedCreditsText]
      // }
    },
    isCouponForRightPlanButOldCoupon (couponProduct) {
      if (this.selectedServicePkg != couponProduct 
        && this.getPureServicePkgName(this.selectedServicePkg) == this.getPureServicePkgName(couponProduct)) {
          return true;
      }
      return false;
    },
    serviceLabel (arg_serviceName) {
      return serviceLabel(arg_serviceName)
    },
    serviceSimplifiedLabel (arg_serviceName) {
      let purePackageName = this.getPureServicePkgName(arg_serviceName)
      if (purePackageName === 'sub_account') {
        return 'Sub Account'
      } else if (purePackageName === 'gold') {
        return 'Credits'
      } else if (purePackageName === 'trial') {
        return 'Trial'
      } else if (['platinum', 'UnlimitedYearly', 'SERVICE_PKG_D', 'SERVICE_PKG_E'].includes(purePackageName)) {
        return 'Unlimited'
      } else if (['SERVICE_PKG_F', 'SERVICE_PKG_G', 'SERVICE_PKG_H', 'SERVICE_PKG_I',
                'SERVICE_PKG_J', 'SERVICE_PKG_K', 'SERVICE_PKG_L', 'SERVICE_PKG_M'
              ].includes(purePackageName)) {
        return 'Agency plan'
      } else if (['UNLIMITED_AGENCY5_FREE', 'UNLIMITED_AGENCY20_FREE', 'UNLIMITED_AGENCY_FREE_EDUCATION'].includes(purePackageName)) {
        return 'Agency free'
      } else if ( purePackageName === 'gold_free') {
        return 'Pay Per Credit FREE'
      } else if (purePackageName === 'platinum_free') {
        return 'Unlimited FREE'
      } else if(purePackageName === 'BASIC_FREE') {
        return 'Basic free'
      } else if(purePackageName === 'PREMIUM_FREE') {
        return 'Premium free'
      } else if (purePackageName === 'SERVICE_PKG_A') {
        return 'Starter'
      } else if (purePackageName === 'SERVICE_PKG_B' || purePackageName === 'SERVICE_PKG_B2') {
        return 'Basic'
      } else if (purePackageName === 'SERVICE_PKG_C') {
        return 'Premium'
      } else if (['SERVICE_PKG_AA', 'SERVICE_PKG_BB'].includes(purePackageName)) {
        return 'Teams plan'
      } else if (purePackageName === 'SERVICE_PKG_CC') {
        return 'White Glove Plan'
      } else if (purePackageName === 'WHITE_GLOVE_FREE') {
        return 'White Glove FREE'
      } else if (purePackageName === 'EXTRA_SEAT_AA') {
        return 'Teams Plan Seat Monthly'
      } else if (purePackageName === 'EXTRA_SEAT_BB') {
        return 'Teams Plan Seat Yearly'
      } else if (purePackageName === 'EXTRA_SEAT_CC') {
        return 'White Glove Seat Monthly'
      } else if (purePackageName === 'SERVICE_PKG_Z') {
        return 'WatchDog Only'
      } else if (purePackageName === 'ANY') {
        return 'All plans'
      }
    },
    getServiceDescription (arg_serviceName) {
      const pureServicePkg = this.getPureServicePkgName(arg_serviceName)

      let price = PRICE_OF_EACH_VERSIONING[arg_serviceName]

      if (pureServicePkg === 'sub_account') {
        return 'Sub Account of the Business account'
      } else if (pureServicePkg === 'platinum' || pureServicePkg === 'platinum_free' || pureServicePkg === 'UnlimitedYearly' || pureServicePkg === 'SERVICE_PKG_D') {
        return 'Unlimited monthly reports'
      } else if (pureServicePkg === 'SERVICE_PKG_E') {
        return 'Unlimited yearly reports'
      } else if (pureServicePkg === 'SERVICE_PKG_F') {
        return 'Agency Unlimited Monthly ( 5 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_G') {
        return 'Agency Unlimited Monthly ( 10 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_H') {
        return 'Agency Unlimited Monthly ( 20 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_I') {
        return 'Agency Unlimited Yearly ( 5 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_J') {
        return 'Agency Unlimited Yearly ( 10 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_K') {
        return 'Agency Unlimited Yearly ( 20 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_L') {
        return 'Agency Unlimited Monthly ( 100 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_M') {
        return 'Agency Unlimited Yearly ( 100 Sub Accounts )'
      } else if (pureServicePkg === 'SERVICE_PKG_A') {
        price = price ? price : 10
        return `\$${price} per month with 5 reports per month`
      } else if (pureServicePkg === 'SERVICE_PKG_B' || pureServicePkg === 'SERVICE_PKG_B2') {
        price = price ? price : 20
        return `\$${price} per month with 12 reports per month`
      } else if (pureServicePkg === 'SERVICE_PKG_C') {
        price = price ? price : 30
        return `\$${price} per month with 20 reports per month`
      } else {
        // this includes trial, canceled, and past_due
        return 'Pay As You Go'
      }
    },
    togglePlantypeForSinglePlan () {
      this.showYearlyForSingleUserPlan = !this.showYearlyForSingleUserPlan
    },
    handleTabChange (tabIndex, servicePkg) {
      const purePackageName = this.getPureServicePkgName(servicePkg)
      if (tabIndex == 0) {
        this.showAgencyPlanOptions = false
        if (!this.isEnteringServicePurcasePage) this.updateSelectedServicePkg(`${DEFAULT_PREFIX}SERVICE_PKG_B2`) // select default package when we back
      } else {
        this.showAgencyPlanOptions = true
        if (!this.isEnteringServicePurcasePage) {
          if (servicePkg && (agencyMonthlyPlans.includes(purePackageName) || agencyYearlyPlans.includes(purePackageName))) {
            this.updateSelectedServicePkg(servicePkg)
          } else {
            const selectedPurePackageName = this.getPureServicePkgName(this.selectedServicePkg)
            if (!agencyMonthlyPlans.includes(selectedPurePackageName) && !agencyYearlyPlans.includes(selectedPurePackageName)) { // current selected package is not agency plan
              // this.updateSelectedServicePkg(`${DEFAULT_PREFIX}SERVICE_PKG_F`)
              this.updateSelectedServicePkg(`${DEFAULT_PREFIX}SERVICE_PKG_AA`)
            }
          }
        }
      }
      this.isEnteringServicePurcasePage = false
    },
    setAgencyTabToVisible () {
      this.isEnteringServicePurcasePage = true
      this.showAgencyPlanOptions = true
    },
    getAgencyPlanPrice (chargeType) {
      if (chargeType == 'monthly') {
        return this.servicePkgAAPrice
        // switch (this.selectedAgencyPlanType) {
        //   case 1:
        //     return this.servicePkgFPrice
        //   case 2:
        //     return this.servicePkgGPrice
        //   case 3:
        //     return this.servicePkgHPrice
        //   case 4:
        //     return this.servicePkgLPrice
        // }
      } else if (chargeType == 'yearly') {
        return this.servicePkgBBPrice
        // switch (this.selectedAgencyPlanType) {
        //   case 1:
        //     return this.servicePkgIPrice
        //   case 2:
        //     return this.servicePkgJPrice
        //   case 3:
        //     return this.servicePkgKPrice
        //   case 4:
        //     return this.servicePkgMPrice
        // }
      } else if (chargeType == 'monthly_white_glove') {
        return this.servicePkgCCPrice
      }
    },
    isAgencyPlan(servicePkg) {
      const pureServicePkg = this.getPureServicePkgName(servicePkg)
      return (
        agencyMonthlyPlans.indexOf(pureServicePkg) > -1 ||
        agencyYearlyPlans.indexOf(pureServicePkg) > -1
      )
    },
    getMaxSubAccountCountInPackage(arg_servicePkg) { // need update for this.
      let servicePkg = this.getPureServicePkgName(arg_servicePkg)
      if (['SERVICE_PKG_F', 'SERVICE_PKG_I'].includes(servicePkg)) {
        return 5
      }
      if (['SERVICE_PKG_G', 'SERVICE_PKG_J'].includes(servicePkg)) {
        return 10
      }
      if (['SERVICE_PKG_H', 'SERVICE_PKG_K'].includes(servicePkg)) {
        return 20
      }
      if (['SERVICE_PKG_L', 'SERVICE_PKG_M'].includes(servicePkg)) {
        return 100
      }
      if (['SERVICE_PKG_AA', 'SERVICE_PKG_BB'].includes(servicePkg)) { // because potentially can add any. maybe can be fixed in syncing cron if over.
        return 200;
      }
      if (['SERVICE_PKG_CC'].includes(servicePkg)) {
        return 200;
      }
      return 0
    },
    isAgencyPlanSelected(type) {
      const pureServicePkg = this.getPureServicePkgName(this.selectedServicePkg);
      if (type == 'monthly') {
        return agencyMonthlyPlans.indexOf(pureServicePkg) > -1 && 'SERVICE_PKG_AA' === pureServicePkg
      } else if (type == 'yearly') {
        return agencyYearlyPlans.indexOf(pureServicePkg) > -1
      } else if (type == 'monthly_white_glove') {
        return agencyMonthlyPlans.indexOf(pureServicePkg) > -1 && 'SERVICE_PKG_CC' === pureServicePkg
      } else {
        return (
          agencyMonthlyPlans.indexOf(pureServicePkg) > -1 ||
          agencyYearlyPlans.indexOf(pureServicePkg) > -1
        )
      }

    },
    hasAbilityOfCreateProject () {
      let subAccountRelation = this.$store.state.selectedSubAccountRelation
      if (subAccountRelation!='' && !subAccountRelation.canCreateProject) {
        return false
      } else if (subAccountRelation=='' && this.user.accountType=='sub_account') {
        return false
      }

      return true
    },

    isOldPlan(arg_servicePkg) {
      return oldPlans.includes(arg_servicePkg)
    }
  },
  computed: {
    servicePkgAPrice () {
      let coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_A`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_A_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_A_BASE_PRICE
    },
    servicePkgBPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_B`, `${DEFAULT_PREFIX}SERVICE_PKG_B2`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_B_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_B_BASE_PRICE
    },
    servicePkgCPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_C`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_C_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_C_BASE_PRICE
    },
    servicePkgDPrice () {
      if (this.periodFreeCouponApplied) return 0;
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_D`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_D_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_D_BASE_PRICE
    },
    servicePkgEPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_E`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_E_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_E_BASE_PRICE
    },
    servicePkgFPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_F`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_F_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_F_BASE_PRICE
    },
    servicePkgGPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_G`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_G_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_G_BASE_PRICE
    },
    servicePkgHPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_H`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_H_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_H_BASE_PRICE
    },
    servicePkgIPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_I`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_I_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_I_BASE_PRICE
    },
    servicePkgJPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_J`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_J_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_J_BASE_PRICE
    },
    servicePkgKPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_K`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_K_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_K_BASE_PRICE
    },
    servicePkgAAPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_AA`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_AA_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_AA_BASE_PRICE
    },
    servicePkgBBPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_BB`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_BB_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_BB_BASE_PRICE
    },
    servicePkgCCPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}SERVICE_PKG_CC`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_CC_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_CC_BASE_PRICE
    },
    servicePkgLPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', 'SERVICE_PKG_L'].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_L_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_L_BASE_PRICE
    },
    servicePkgMPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', 'SERVICE_PKG_M'].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.SERVICE_PKG_M_BASE_PRICE, coupon)
      }
      return this.SERVICE_PKG_M_BASE_PRICE
    },
    extraSeatAAPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}EXTRA_SEAT_AA`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.EXTRA_SEAT_AA_BASE_PRICE, coupon)
      }
      return this.EXTRA_SEAT_AA_BASE_PRICE
    },
    extraSeatBBPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}EXTRA_SEAT_BB`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.EXTRA_SEAT_BB_BASE_PRICE, coupon)
      }
      return this.EXTRA_SEAT_BB_BASE_PRICE
    },
    extraSeatCCPrice () {
      const coupons = this.serviceCoupons.filter(c => ['Percent Off', 'Amount Off'].includes(c.couponType) && !c.exceeded && ['ANY', `${DEFAULT_PREFIX}EXTRA_SEAT_CC`].includes(c.product))
      if (coupons.length) {
        let coupon = coupons[0]
        return this.calcDiscountedPrice(this.EXTRA_SEAT_CC_BASE_PRICE, coupon)
      }
      return this.EXTRA_SEAT_CC_BASE_PRICE
    },
    selectedPkgPrice () {
      switch (this.selectedServicePkg) {
        case `${DEFAULT_PREFIX}SERVICE_PKG_B2`:
          return this.servicePkgBPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_C`:
          return this.servicePkgCPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_D`:
          return this.servicePkgDPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_E`:
          return this.servicePkgEPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_AA`:
          return this.servicePkgAAPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_BB`:
          return this.servicePkgBBPrice
        case `${DEFAULT_PREFIX}SERVICE_PKG_CC`:
          return this.servicePkgCCPrice
      }
      return 0
    },
    isCreditsUser () {
      let accountType = this.$store.state.user.accountType
      return accountType === 'trial' || accountType === 'gold'
    },
    isUnlimitedUser () {
      return this.$store.getters.isUnlimitedUser
    },
    isAgencyUnlimitedUser () {
      return this.$store.getters.isAgencyUnlimitedUser
    },
    // does the current user "owns" any agency accounts
    hasOwnerAccounts () {
      let user = this.$store.state.user
      return user.ownerAccounts && user.ownerAccounts.length > 0
    },
    isNonUnlimitedSubscription () {
      let accountType = this.$store.state.user.accountType
      let nonUnlimitedService = ['SERVICE_PKG_A', 'SERVICE_PKG_B', 'SERVICE_PKG_B2', 'SERVICE_PKG_C']
      return nonUnlimitedService.includes(accountType)
    },
    /* has once had a subscription but, is currently a credits user */
    canReactivate () {
      const previousSubscription = this.$store.state.user.purchases.find((p) => {
        return oldPlans.indexOf(p.servicePackage) > -1
      })
      return !!previousSubscription && this.isCreditsUser
    },
    isYearlyPakcageSelected () {
      if (CURRENT_YEARLY_PLANS.includes(this.selectedServicePkg)) {
        return true;
      }
      return false;
    },
    isYearlyExtraPakcageSelected () {
      let accountType = this.getPureServicePkgName(this.selectedServicePkg)
      if (['EXTRA_SEAT_BB'].includes(accountType)) {
        return true;
      }
      return false;
    }
  }
}
