<template>
<div class="supporting-keyword-root">
  <div>
    <div class="mt-10 location-lang-domain-area">
      <div>
        <div class="card-small-title">
          Select Location
        </div>
        <v-autocomplete outlined dense hide-details
          :items="availableLocations"
          item-text="location_name"
          item-value="location_code"
          v-model="selectedLocation"
          color="grayColor"
          class="mt-5">
        </v-autocomplete>
      </div>
      <div>
        <div class="card-small-title">
          Select Language
        </div>
        <v-autocomplete outlined dense hide-details
          :items="targetLanguages"
          v-model="selectedLanguage"
          item-text="language_name"
          item-value="language_code"
          color="grayColor"
          class="mt-5">
        </v-autocomplete>
      </div>
      <div>
        <div class="card-small-title">
          Domain name
        </div>
        <v-text-field outlined dense hide-details
          class="mt-5"
          color="grayColor"
          readonly
          v-model="domain"
        ></v-text-field>
      </div>
    </div>
    <div class="mt-10" style="max-width: 680px;">
      <div class="d-flex align-center gap10">
        <div class="card-small-title">
          Enter your target keywords or topics
        </div>
        <span class="input-count-info"
        :style="(topics?.length || 0) > 10 ? 'color: red; border-color: red;' : ''"
        >{{ topics.length }} / 10</span>
        <div class="flex-grow-1"></div>
      </div>
      <div class="customized-multiselect1">
        <multiselect class="mt-5"
          v-model="topics"
          placeholder="Enter the keyword or topic you wish to explore further."
          :options="[]"
          :multiple="true"
          :taggable="true"
          :optionsLimit="10"
          @tag="addTopicAction">
        </multiselect>
      </div>
      <div class="mainColor--text mt-3">
        If you have your own list of keywords, you can import it and we will cluster your list. Please note that the list can’t have more than 1000 keywords.
        <a class="link15Color--text text-decoration-underline"
          @click="() => {
            $refs.fileRef.click()
          }">Import it here.</a>
        <input type="file" accept=".xlsx" @change="(e) => fileSelectionChange(e.target.files)" style="visibility: hidden;" ref="fileRef" />
      </div>
      <div class="mt-3 d-flex">
        <div v-if="selectedFile && selectedFile.name" class="selected-file">
          <span class="font-weight-bold">{{ selectedFile.name }}</span>
          <span class="material-icons" @click="resetFileSelected">cancel</span>
        </div>
      </div>
    </div>
    <div class="mt-10" style="max-width: 680px;">
      <div class="d-flex align-center gap10">
        <div class="card-small-title">
          Enter your competitor URLs <span v-if="!checkOnlyCompetitors" class="tag optional-field-label ml-1">Optional</span>
        </div>
        <span
          class="input-count-info"
          :style="competitorUrlsCountInputed > 20 ? 'color: red; border-color: red;' : ''"
        >
          {{ competitorUrlsCountInputed }} / 20
        </span>
        <div class="flex-grow-1"></div>
      </div>
      <div class="mainColor--text mt-3">
        <i class="material-icons" style="color: darkorange; vertical-align: bottom; font-size: 1.2rem;">warning_amber</i> URLs should link directly to specific features or product pages rather than main homepages, <br/>
        e.g. use <span class="font-weight-bold">https://www.domain.com/platform/marketing-automation</span> instead of https://www.domain.com/.
      </div>
      <div class="mt-3">
        <v-textarea outlined hide-details
          color="greenColor"
          v-model="competitorUrls"
          placeholder="Enter a list of URLs (separated by line breaks)"
          >
        </v-textarea>
      </div>
      <div class="mt-3 d-flex align-center gap10">
        <div class="d-flex align-center">
          <vue-toggles
            :value="checkOnlyCompetitors"
            width="40" height="20"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
            @click="checkOnlyCompetitors = !checkOnlyCompetitors"
          ></vue-toggles>
          <div class="mainColor--text ml-2">
            I want to analyze competitors only
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10 d-flex align-center gap10">
      <v-btn rounded
        color="profileAnchorColor"
        class="button-with-icon text-none whiteColor--text"
        :disabled="isCalculating || !canStartSearchKeywords || (isWhiteGloveUser && !isMissionControlSession)"
        @click="() => hasUnlimitedAccess?startSearchKeywords():(() => {showSearchKeywordsCostModal = true})()">
        <span>Search keywords</span>
      </v-btn>
    </div>
  </div>

  <div class="result-area mt-10">
    <template v-if="currentStep == STEPS.LOADING_KEYWORD && extractedKeywords.length > 0">
      <div class="white-common-box pa-5">
        <div class="d-flex align-center flex-wrap">
          <div class="general-info flex-grow-1">{{ finalKeywords.length }} keywords in {{ finalClusterNames.length }} topic clusters</div>
          <div class="d-flex gap10">
            <div class="keyword-filter-area" @click="() => { showKeywordIntentFilterPopup = true; }">
              <div>KEYWORD INTENT</div>
              <div class="filter-btn">
                {{ keywordIntentsFilter.length }} filters applied
              
                <div v-if="showKeywordIntentFilterPopup" v-click-outside="() => { showKeywordIntentFilterPopup = false; }"
                  class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                  <v-checkbox dense hide-details="auto" color="#007aff"
                    v-for="k_intent in allKeywordIntents" :key="k_intent"
                    style="text-transform: capitalize;"
                    v-model="keywordIntentsFilter" :value="k_intent" :true-value="[]" :label="k_intent"
                    @change="persistFilter('keywordIntentsFilter')">
                  </v-checkbox>
                </div>
              </div>
            </div>
            <div class="keyword-filter-area" @click="() => { showKeywordTypeFilterPopup = true; }">
              <div>KEYWORD TYPE</div>
              <div class="filter-btn">
                {{ filtersAppliedCount }} filters applied
              
                <div v-if="showKeywordTypeFilterPopup" v-click-outside="() => { showKeywordTypeFilterPopup = false; }"
                  class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                  <v-checkbox dense hide-details="auto" color="#007aff"
                    v-for="kType in keywordTypeOptions" :key="kType"
                    v-model="keywordTypesFilter" :value="kType" :true-value="[]" :label="kType"
                    @change="persistFilter('keywordTypesFilter')">
                  </v-checkbox>
                  <div class="d-flex align-center mt-5">
                    <vue-toggles
                      :value="includeQuestionKeyword"
                      width="40" height="20"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                      @click="() => {
                        includeQuestionKeyword = !includeQuestionKeyword;
                        persistFilter('includeQuestionKeyword')
                      }">
                    </vue-toggles>
                    <div class="ml-2 mainColor--text">
                      Question Keywords
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="keyword-filter-area" @click="() => { showIncludeExcludeFilterPopup = true; }">
              <div>FILTERS</div>
              <div class="filter-btn">
                {{ filterTermFilerCount }} filters applied
              
                <div v-if="showIncludeExcludeFilterPopup" v-click-outside="() => { showIncludeExcludeFilterPopup = false; }"
                  class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                  <div class="d-flex align-center">
                    <div class="mainColor--text font-weight-bold flex-grow-1">Include words</div>
                    <div class="clear-btn" :class="{ 'disabled': includeFilterTermText == '' }" @click="() => { includeFilterTermText = ''; persistFilter('includeFilterTermText'); }">
                      <i class="material-icons">close</i>
                      <span>Clear</span>
                    </div>
                  </div>
                  <v-textarea outlined rows="4" v-model="includeFilterTermText" @change="persistFilter('includeFilterTermText')"></v-textarea>
                  <div class="d-flex align-center">
                    <div class="mainColor--text font-weight-bold flex-grow-1">Exclude words</div>
                    <div class="clear-btn" :class="{ 'disabled': excludeFilterTermText == '' }" @click="() => { excludeFilterTermText = ''; persistFilter('excludeFilterTermText'); }">
                      <i class="material-icons">close</i>
                      <span>Clear</span>
                    </div>
                  </div>
                  <v-textarea outlined hide-details rows="4" v-model="excludeFilterTermText" @change="persistFilter('excludeFilterTermText')"></v-textarea>
                </div>
              </div>
            </div>
            <v-btn outlined color="mainColor"
              class="button-with-icon text-none"
              @click="downloadClustersAndKeywords">
              Download
            </v-btn>
          </div>
        </div>
        <clusters-chart
          :topics="topicsUsedInExtrackingKeyword"
          :clusterNames="clusterNames"
          :showClustersCount="showClustersCount"
          @showMoreClusters="() => {
            showMoreClusters()            
          }"
          @showClusterDetail="(cName) => showClusterDetail(cName)"
          ></clusters-chart>
      </div>
      <div class="mt-10">
        <div class="d-flex align-center sticky-keywords-list-header">
          <div class="flex-grow-1">
            <div class="card-small-title mb-2">Keywords ({{ finalKeywords.length }})</div>
            <div class="mainColor--text">To get the keyword data, you can select up to 100 keywords in different clusters below.</div>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
            <v-tooltip top color="transparent" :disabled="(isWhiteGloveUser && !isMissionControlSession)">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn rounded outlined
                    color="mainColor"
                    class="button-with-icon"
                    :disabled="(researchReport?.clustersAIFilteringAttempts || 0) >= MAX_CLUSTERS_AI_FILTERING_ATTEMPTS || isCalculating || (isWhiteGloveUser && !isMissionControlSession)"
                    @click="() => {
                      showAIFormModal = true
                    }">
                    <i class="material-icons-outlined">auto_awesome</i>
                    <span style="text-transform: initial;">Filter clusters with AI</span>
                  </v-btn>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                {{ (researchReport?.clustersAIFilteringAttempts || 0) >= MAX_CLUSTERS_AI_FILTERING_ATTEMPTS ? `You have already filtered the clusters ${MAX_CLUSTERS_AI_FILTERING_ATTEMPTS} times` : `Enter your topic title or target keyword. AI will filter and prioritize key terms, saving time by highlighting the most relevant supporting keywords. You can use this feature up to ${MAX_CLUSTERS_AI_FILTERING_ATTEMPTS} times on the same cluster list.` }}
              </div>
            </v-tooltip>
            <v-tooltip top color="transparent" :disabled="!(finalKeywords.length === 0 || finalKeywords.length > 100) || (isWhiteGloveUser && !isMissionControlSession)">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn rounded
                    color="profileAnchorColor"
                    class="button-with-icon text-none whiteColor--text"
                    :disabled="finalKeywords.length === 0 || finalKeywords.length > 100 || (isWhiteGloveUser && !isMissionControlSession)"
                    @click="() => {
                      showGetKeywordDataCostWarnModal = true;
                    }">
                    <i class="material-icons-outlined">query_stats</i>
                    <span>Get Keyword Data</span>
                  </v-btn>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                {{
                  finalKeywords.length === 0
                  ?'Please select at least one cluster first'
                  :finalKeywords.length > 100
                    ?'Number of selected keywords must be less than 100. Please, deselect some keywords or clusters.'
                    :''
                }}
              </div>
            </v-tooltip>
            
          </div>
        </div>

        <v-btn
          v-if="selectedKeywords.length"
          outlined
          class="mb-2"
          :style="`border-color: #e1e1e1; background-color: #fff; text-transform: initial; color: var(--v-mainColor-base); letter-spacing: normal;`"
          @click="selectedKeywords = []; this.persistSelectedKeywords()"
        >
          Deselect all
        </v-btn>

        <v-btn
          v-else
          outlined
          class="mb-2"
          :style="`border-color: #e1e1e1; background-color: #fff; text-transform: initial; color: var(--v-mainColor-base); letter-spacing: normal;}`"
          @click="selectedKeywords = [...filteredKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}))]; this.persistSelectedKeywords()"
        >
          Select all
        </v-btn>

        <v-card v-for="clusterName in clusterNamesToDisp" :key="clusterName"
          :id="`clusterId_${clusterNameToId(clusterName)}`"
          class="white-common-box pa-5 mt-3 cluster-box">
          <div class="cluster-header">
            <i class="material-icons expand-icon" v-if="!expandedClusterNames.includes(clusterName)"
              @click="() => { expandedClusterNames.push(clusterName) }">keyboard_arrow_right</i>
            <i class="material-icons expand-icon" v-else
              @click="() => { expandedClusterNames = expandedClusterNames.filter(item => item != clusterName) }">keyboard_arrow_down</i>
            <v-checkbox color="#007aff" hide-details="auto" class="mt-0 pt-0" :input-value="finalClusterNames.includes(clusterName)" @change="toggleSelectedCluster(clusterName)"></v-checkbox>
            <span class="cluster-name">{{ clusterName }}</span>
            <span class="keywords-count">
              {{ keywordsByCluster[clusterName]?.length }}
              {{ keywordsByCluster[clusterName]?.length > 1 ? 'keywords' : 'keyword' }}
            </span>
            <span
              class="best-match-label"
              v-if="clusterName.startsWith('Supporting keywords for:')"
            >
              best match
            </span>
            <div class="flex-grow-1"></div>
            <span class="material-icons remove-cluster-icon" @click="() => {
              hiddenClusters = [
                ...hiddenClusters.filter(c => c !== clusterName),
                clusterName
              ]
              persistHiddenClusters()
            }">close</span>
          </div>
          <div class="cluster-body mt-5" v-if="expandedClusterNames.includes(clusterName)">
            <div class="keywords-wrapper">
              <div v-for="item in keywordsByCluster[clusterName]" :key="item.keyword">
                <div class="one-keyword-item">
                  <v-checkbox color="#007aff" hide-details="auto" class="mt-0 pt-0" :input-value="Boolean(selectedKeywords.filter(selectedKeywords => item.keyword === selectedKeywords.keyword && item.clusterName === selectedKeywords.clusterName).length)" @change="toggleSelectedKeyword(item.keyword, item.clusterName)"></v-checkbox>
                  <span class="font-weight-bold">{{ item.keyword }}</span>
                  <span v-if="item.keyword_intent" style="text-transform: capitalize;">[ {{ item.keyword_intent }} ]</span>
                  <span v-if="item.source">( {{ item.source }} )</span>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <!-- {{ keywordsByCluster }} -->
        <div class="text-center mt-3">
          <v-btn outlined rounded color="mainColor"
            v-if="showClustersCount < clusterNames.length"
            @click="() => {
              showMoreClusters()
            }"
            class="text-none">
            <span>Load more</span>
          </v-btn>
        </div>
      </div>
    </template>
    <div class="mt-10" v-if="researchReport && currentStep != STEPS.LOADING_KEYWORD && !isCalculating">
      <keyword-research-result
        :report="researchReport"
        reportType="SupportKeywordResearch"
        :showSiloExtraActions="false"
        title="Supporting Keyword Research"
        :researchHistory="researchHistory"
        :hasUnlimitedAccess="hasUnlimitedAccess"
        @onChangeResearchId="(id) => { selectedResearchReportId = id }"
        @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
        @refreshReport="() => loadResearchReport()"
        @onAIResultReady="() => loadResearchReport()"
        @requestedAdvancedData="(keywords) => getAdvancedDataAction(keywords)">
      </keyword-research-result>
    </div>
  </div>

  <div class="modal search-keywords-cost-modal" :class="{'is-active': showSearchKeywordsCostModal}">
    <div class="modal-background" @click.stop="showSearchKeywordsCostModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Search keywords</p>
          <div class="mainColor--text message-text" style="font-size: 1rem; line-height: 150%;">
            You have selected:
            <ul class="mt-2 mb-2" style="list-style: initial;">
              <li class="font-weight-bold" v-if="!checkOnlyCompetitors && topics.length">{{ topics.length }} topic{{topics.length >1 ?'s':''}}</li>
              <li class="font-weight-bold" v-if="competitorsListInputed?.length">{{ competitorsListInputed.length }} competitor url{{competitorsListInputed.length >1 ?'s':''}}</li>
            </ul>
            It will cost <span class="font-weight-bold">{{ (checkOnlyCompetitors?0:topics.length) + Math.ceil(competitorsListInputed.length / 5) }} POP Credit{{(checkOnlyCompetitors?0:topics.length) + Math.ceil(competitorsListInputed.length / 5) >1 ?'s':''}}</span> to search keywords for the selected items. Results will be organized in keyword clusters.<br />
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showSearchKeywordsCostModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              @click.stop="startSearchKeywords">
              <span>Search keywords</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showSearchKeywordsCostModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  
  <div class="modal keyword-data-cost-modal" :class="{'is-active': showGetKeywordDataCostWarnModal}">
    <div class="modal-background" @click.stop="showGetKeywordDataCostWarnModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Get cluster keyword data</p>
          <div class="mainColor--text message-text">
            <div class="mb-2">You’ve selected <span class="font-weight-bold">{{ finalClusterNames.length }} cluster{{finalClusterNames.length >1 ?'s':''}}</span> containing a total of <span class="font-weight-bold">{{ finalKeywords.length }} keyword{{finalKeywords.length >1 ?'s':''}}</span>.</div>
            <div class="mb-1">We’ll retrieve detailed metrics for {{finalKeywords.length >1 ?'each':'the'}} keyword, including:</div>
            <ul class="mb-2" style="list-style: initial;">
              <li>Keyword Difficulty Score</li>
              <li>Search Volume</li>
              <li>Search Volume trend</li>
              <li>Ranking URL and Position</li>
            </ul>
            <template v-if="!hasUnlimitedAccess">
              <br/>
              This action will consume <span class="font-weight-bold">{{ Math.ceil(finalKeywords.length / 100) }} POP Credit{{Math.ceil(finalKeywords.length / 100) >1 ?'s':''}}</span>. Click <span class="font-weight-bold">Get Data</span> to proceed.
            </template>
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showGetKeywordDataCostWarnModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              @click.stop="() => createSupportKeywordResearchAction()">
              <span>Get data</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showGetKeywordDataCostWarnModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showAIFormModal}">
    <div class="modal-background" @click.stop="showAIFormModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Filter clusters with AI</p>
          <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
            AI will filter keywords to highlight the most relevant options for your content strategy, identifying supporting keywords. You can repeat this process up to {{MAX_CLUSTERS_AI_FILTERING_ATTEMPTS}} times for the same cluster list.<br /><br />
            <v-text-field outlined dense
              v-model="aiTopicFieldValue"
              placeholder="Enter topic or target keyword"
              :error="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
              :error-messages="(aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80)?'Must be 1 to 80 characters':''"
              color="grayColor"
            ></v-text-field>
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showAIFormModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              :disabled="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
              @click.stop="() => {
                analyzeClustersWithAI(); showAIFormModal = false
              }"
            >
              <span>Run</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showAIFormModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  
</div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect'

import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import languages from '@/consts/dataforseo_languages.json';
import ClustersChart from './ClustersChart.vue';
import { getHostName, getPureServicePkgName } from '@/utils'
import { getSplittedKeywords, getValidKeywords } from '@/components/Campaigns/CommonFunctions.js'
import KeywordResearchResult from '@/components/Campaigns/KeywordResearchResult.vue';
import { mixin as export_mixin } from '@/components/export_mixin'
import {
  whiteGlovePlans,
} from "@/components/plans_constants"

const MAX_CLUSTERS_AI_FILTERING_ATTEMPTS = 10
const ALLOWED_KEYWORD_COUNT = 10
const KeywordTypeOptionsFull = [
  "Related Keywords",
  // "Keyword Suggestions",
  "Competitor's Keyword",
  "People Also Ask",
  "Google Suggest",
  "AI Generated",
]
const question_prefixs = 'who|what|where|when|why|how|was|were|did|do|does|is|are'.split('|')

export default {
  mixins: [ export_mixin ],
  props: ['hasUnlimitedAccess'],
  components:  {
    Multiselect,
    ClustersChart,
    KeywordResearchResult,
  },
  data () {
    return {

      selectedLocation: '',
      selectedLanguage: '',
      domain:'',
      topics: [],
      competitorUrls: '',
      checkOnlyCompetitors: false,
      selectedFile: null,
      // selectedFile: {
      //   name: 'test.xlsx'
      // },
      STEPS: {
        'LOADING_KEYWORD': 'LOADING_KEYWORD',
        'LOADING_RESULT_FOR_KEYWORD': 'LOADING_RESULT_FOR_KEYWORD',
      },
      currentStep: '',

      hiddenClusters: [],
      
      topicsUsedInExtrackingKeyword: [],
      showClustersCount: 10,
      expandedClusterNames: [],
      selectedKeywords: [],

      keywordTypeOptions: KeywordTypeOptionsFull,
      keywordTypesFilter: [],
      showKeywordTypeFilterPopup: false,
      includeQuestionKeyword: false,

      showKeywordIntentFilterPopup: false,
      keywordIntentsFilter: [],

      includeFilterTermText: '',
      includeAllFilter: false,
      excludeFilterTermText: '',
      excludeAllFilter: false,
      showIncludeExcludeFilterPopup: false,

      showSearchKeywordsCostModal: false,
      
      showGetKeywordDataCostWarnModal: false,

      campaign: null,
      researchHistory: [],
      selectedResearchReportId: null,
      researchReport: null,

      isStartingAsyncTask: false,
      isAwaitingAsyncTaskStatus: false,

      showAIFormModal: false,
      aiTopicFieldValue: '',
      
    }
  },
  computed: {
    MAX_CLUSTERS_AI_FILTERING_ATTEMPTS() {
      return MAX_CLUSTERS_AI_FILTERING_ATTEMPTS
    },
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    competitorUrlsCountInputed () {
      return this.competitorsListInputed.length
    },
    competitorsListInputed() {
      return this.competitorUrls.split('\n').filter(item => item.trim())
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    canStartSearchKeywords () {
      if (this.checkOnlyCompetitors) {
        return this.competitorUrlsCountInputed > 0 && this.competitorUrlsCountInputed <= 20
      }
      return ((this.topics.length || 0) > 0 && (this.topics.length || 0) <= 10) || (this.competitorUrlsCountInputed > 0 && this.competitorUrlsCountInputed <= 20) || this.selectedFile
    },
    extractedKeywords () {
      return (this?.researchReport?.allClusteredKeywords || []).filter(item => !this.hiddenClusters.includes(item.clusterName))
    },
    clusterNames () {
      let rlt = [];
      this.filteredKeywords.map(item => {
        if (!rlt.includes(item.clusterName)) {
          rlt.push(item.clusterName);
        }
      })
      rlt.sort();
      return rlt;
    },
    allSources () {
      let rlt = [];
      this.extractedKeywords.map(item => {
        if (!rlt.includes(item.source)) {
          rlt.push(item.source);
        }
      })
      return rlt;
    },
    allKeywordIntents () {
      let rlt = [];
      this.extractedKeywords.map(item => {
        if (item.keyword_intent && !rlt.includes(item.keyword_intent)) {
          rlt.push(item.keyword_intent);
        }
      })
      return rlt;
    },
    clusterNamesToDisp () {
      let sortedClusterNames = [...this.clusterNames]
      sortedClusterNames.sort((a, b) => {

        let aValue = 1
        if (a.startsWith('Supporting keywords for:')) {
          aValue = 0
        }
        let bValue = 1
        if (b.startsWith('Supporting keywords for:')) {
          bValue = 0
        }

        return aValue - bValue
      })
      return sortedClusterNames.slice(0, this.showClustersCount)
    },
    hasAlreadyAnalyzedClustersWithAI() {
      return this.extractedKeywords.filter(keyword => keyword.is_best_match_keyword).length > 0
    },
    keywordsByCluster () {
      let rlt = {}
      for ( const item of this.filteredKeywords) {
        if (!(item.clusterName in rlt)) {
          rlt[item.clusterName] = []
        }
        rlt[item.clusterName].push({
          ...item
        })
      }
      return rlt
    },
    filterTermFilerCount () {
      let includeFilters = getSplittedKeywords(this.includeFilterTermText)
      let exlcudeFilters = getSplittedKeywords(this.excludeFilterTermText)
      return includeFilters.length + exlcudeFilters.length;
    },
    filteredKeywords () {
      return this.extractedKeywords.filter(item => { // Apply include filters
        const filterTerms = this.includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
        if (filterTerms.length == 0) return true;

        if (!this.includeAllFilter) {
          for (const term of filterTerms) {
            if (item['keyword'].includes(term)) {
              return true;
            }
          }
          return false;
        } else {
          for (const term of filterTerms) {
            if (!item['keyword'].includes(term)) {
              return false;
            }
          }
          return true;
        }
      })
      .filter(item => {  // Apply exclude filters
        const filterTerms = this.excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
        if (filterTerms.length == 0) return true;

        if (!this.excludeAllFilter) {
          for (const term of filterTerms) {
            if (item['keyword'].includes(term)) {
              return false;
            }
          }
          return true;
        } else {
          for (const term of filterTerms) {
            if (!item['keyword'].includes(term)) {
              return true;
            }
          }
          return false;
        }
      })
      .filter(item => {
        if (this.includeQuestionKeyword) {
          for (let index = 0; index < question_prefixs.length; index++) {
            const element = question_prefixs[index];
            if (item['keyword'].startsWith(element)) {
              return true
            }
          }
          return false
        } else {
          return true
        }
      })
      .filter(item => { // Keyword Type filter
        if (this.keywordTypesFilter.length > 0) {
          for (let index = 0; index < this.keywordTypesFilter.length; index++) {
            const keywordType_ = this.keywordTypesFilter[index];
            if (item['source'].includes(keywordType_)) {
              return true
            }
          }
          return false
        }
        return true
      })
      .filter(item => { // keyword intent filter
        if (this.keywordIntentsFilter.length > 0) {
          return this.keywordIntentsFilter.includes(item?.['keyword_intent'])
        }
        return true;
      })
    },
    finalKeywords () {
      // NOTE(Hichem): we transform selectedkeywords to a map for enhancing performance when a lot of keywords are selected (more than 1000)
      const selectedKeywordsMap = Object.fromEntries(this.selectedKeywords.map(selectedKeyword => [`${selectedKeyword.keyword}-${selectedKeyword.clusterName}`, true]))
      return this.filteredKeywords.filter(item => {
        return selectedKeywordsMap[`${item.keyword}-${item.clusterName}`]
      })
    },
    finalClusterNames () {
      let rlt = [];
      this.finalKeywords.map(item => {
        if (!rlt.includes(item.clusterName)) {
          rlt.push(item.clusterName);
        }
      })
      return rlt;
    },
    filtersAppliedCount () {
      let rlt = this.keywordTypesFilter.length;
      if (this.includeQuestionKeyword) {
        rlt += 1
      }
      return rlt;
    },
    asyncTasks() {
      return this.$store.state.asyncTasks
    },
    currentAsyncTask() {
      return this.asyncTasks.filter(task => task.id === this.researchReport?.taskId)[0]
    },
    isCalculating() {
      return this.isStartingAsyncTask || this.isAwaitingAsyncTaskStatus || this.currentAsyncTask?.is_pending_or_in_progress
    },
  },
  watch: {
    campaignId: function(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
        this.loadSupportKeywordResearchHistoryForCampaign()
      }
    },
    selectedResearchReportId: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal && newVal !== this?.researchReport?.id) {
        this.loadResearchReport()
      }
    },
    currentAsyncTask: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isAwaitingAsyncTaskStatus = false;
      }
      if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
        this.loadResearchReport(true)
      }
    }
  },

  methods: {
    persistFilter(key) {
      if (this.researchReport) {
        this.$store.dispatch('updateSupportKeywordReport', {
          data: {
            researchId: this.researchReport.id,
            key: 'frontendFilters',
            value: {
              [key]: this[key]
            },
          },
          subAccountRelationId: this.subAccountRelationId
        })
      }
    },
    loadFiltersFromResearchReport() {
      Object.entries(this?.researchReport?.frontendFilters || {}).forEach(([key, value]) => {
        this[key] = value
      })
    },
    persistSelectedKeywords() {
      if (this.researchReport) {
        this.$store.dispatch('updateSupportKeywordReport', {
          data: {
            researchId: this.researchReport.id,
            key: 'frontendSelectedKeywords',
            value: this.selectedKeywords,
          },
          subAccountRelationId: this.subAccountRelationId
        })
      }
    },
    loadSelectedKeywordsFromResearchReport() {
      this.selectedKeywords = this?.researchReport?.frontendSelectedKeywords || []
    },
    persistHiddenClusters() {
      if (this.researchReport) {
        this.$store.dispatch('updateSupportKeywordReport', {
          data: {
            researchId: this.researchReport.id,
            key: 'frontendHiddenClusters',
            value: this.hiddenClusters,
          },
          subAccountRelationId: this.subAccountRelationId
        })
      }
    },
    loadHiddenClustersFromResearchReport() {
      this.hiddenClusters = this?.researchReport?.frontendHiddenClusters || []
    },
    showMoreClusters () {
      this.showClustersCount += 10;
      this.$nextTick(() => {
        setTimeout(() => {
          let divObj = document.getElementById("mainBodyScrollArea");
          divObj.scrollTop = divObj.scrollHeight;
        }, 500);
      })
    },
    downloadClustersAndKeywords () {
      let data = []
      data.push(['Cluster', 'Keyword'])
      this.finalKeywords.map(item => {
        data.push([item.clusterName, item.keyword])
      })
      let csvData = data.map(item => {
        return item.join(',')
      }).join('\n')
      const timestamp = moment().format('YYYY_M_D_h_mm_ss')
      this.exportToCSV(csvData, `clusters_and_keywords_${timestamp}.csv`)
    },
    fileSelectionChange (fileList) {
      if (!fileList) return;

      let file = fileList[0]
      if (file && file.name.endsWith('.xlsx')) {
        this.selectedFile = file
      } else {
        this.$notify({
          'group': 'info', type: 'warning',
          text: 'Please select valid file' + file.name
        })
      }
    },
    // clusterChartPaddingStyle (idx) {
    //   let gap = 0;
    //   let countInOneSide = this.clusterNames / 2
    //   if ( idx % 2 == 0) { // left side
    //     let sideIndex = Math.int(idx / 2);
    //   } else {

    //   }
    // },
    clusterNameToId (name) {
      return name.replaceAll(' ', '_')
    },
    toggleSelectedKeyword (keyword, clusterName) {
      const isSelected = Boolean(this.selectedKeywords.filter(item => item.keyword === keyword && item.clusterName === clusterName).length)
      if (isSelected) {
        this.selectedKeywords = this.selectedKeywords.filter(item => item.keyword !== keyword || item.clusterName !== clusterName)
      } else {
        this.selectedKeywords = [...this.selectedKeywords, {keyword: keyword, clusterName: clusterName}]
      }
      this.persistSelectedKeywords()
    },
    toggleSelectedCluster (clusterName, persist=true) {
      if (this.finalClusterNames.includes(clusterName)) {
        this.selectedKeywords = this.selectedKeywords.filter(item => item.clusterName !== clusterName)
      } else {
        const clusterKeywords = this.filteredKeywords.filter(item => item.clusterName === clusterName)
        this.selectedKeywords = [...this.selectedKeywords, ...clusterKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}))]
      }
      if (persist) {
        this.persistSelectedKeywords()
      }
    },
    selectBestMatchClusters () {
      const bestMatchClusterNames = this.clusterNamesToDisp.filter(clusterName => clusterName.startsWith('Supporting keywords for:'))
      
      if (bestMatchClusterNames.length) {
        this.selectedKeywords = []
        bestMatchClusterNames.forEach(name => {
          this.toggleSelectedCluster(name, false)
        })
      }
    },
    addTopicAction (newTopic) {
      if ( newTopic ) {
        let topics = getSplittedKeywords(newTopic)
        let result = [ ...this.topics, ...topics ]
        this.topics = result.filter((k, pos) => result.indexOf(k) == pos)
      }
    },
    loadResearchReport (scrollBottom = false) {
      // reload user to update ui with any credit usage
      this.$store.dispatch("loadUser").then(response => {
        this.$store.commit("setUser", response.data.user);
      });

      return this.$store.dispatch('loadSupportKeywordResearchData', {
        researchId: this.selectedResearchReportId,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.researchReport = response.data.research;
          // this.keywords = this.researchReport.keywords
          this.selectedLanguage = this.researchReport.languageCode
          this.selectedLocation = parseInt(this.researchReport.locationCode || 0)
          
          this.competitorUrls = this.researchReport.competitorUrls.join('\n')
          this.checkOnlyCompetitors = Boolean(this.researchReport.checkOnlyCompetitors)

          this.topics = this.researchReport.targetTopics

          this.topicsUsedInExtrackingKeyword = !this.checkOnlyCompetitors
            ?this.researchReport.targetTopics
            :[]
          if (this.researchReport.competitorUrls.length) {
            this.topicsUsedInExtrackingKeyword = [
              ...this.topicsUsedInExtrackingKeyword,
              `${this.researchReport.competitorUrls.length} competitor url${this.researchReport.competitorUrls.length>1?'s':''}`
            ]
          }

          this.loadFiltersFromResearchReport()
          this.loadSelectedKeywordsFromResearchReport()
          this.loadHiddenClustersFromResearchReport()

          if (this.researchReport.currentStep == 0) {
            this.currentStep = this.STEPS.LOADING_KEYWORD
            // NOTE:
            // teh feature for persisting selectedKeywords was introduced around the date of 2025-01-29
            // - for reports created before that, we pre-select best match clusters (or all clusters if user did not filter clusters with AI yet)
            // - reports created after that do not need this, because pre-selection is made in the backend when keywords are generated 
            if (new Date(this.researchReport.createdAt) < new Date('2025-01-29') && !this.selectedKeywords.length) {
              
              if (this.hasAlreadyAnalyzedClustersWithAI) {
                this.selectBestMatchClusters()
              } else {
                this.selectedKeywords = this.extractedKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}));
              }
              this.persistSelectedKeywords()
            }
          } else if (this.researchReport.currentStep == 1) {
            
          }

          if (scrollBottom) {
            setTimeout(() => {
              document.querySelector(".result-area").scrollIntoView({
                behavior: 'smooth'
              });
            }, 500);
          }
        }
      })
    },
    getCampaignById () {
      this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.campaign = response.data.campaign
            // this.$store.commit('setCurrentCampaign', this.campaign)
            
            this.domain = getHostName(this.campaign.targetUrl)
            this.selectedLanguage = this.campaign.languageCode || 'en'
            if (this.campaign.locationCode) {
              this.selectedLocation = parseInt(this.campaign.locationCode || 0)
            }
          }
        })
    },
    loadSupportKeywordResearchHistoryForCampaign( scrollBottom = false ) {
      this.$store.dispatch('getSupportKeywordResearchHistoryForCampaign', {
        campaignId: this.campaignId
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.researchHistory = response.data.researches
          if (this.researchHistory.length > 0 && (!this.researchReport || this.selectedResearchReportId < this.researchHistory[0].id )) {
            this.selectedResearchReportId = this.researchHistory[0].id
          }
          if (scrollBottom) {
            setTimeout(() => {
              document.querySelector(".result-area").scrollIntoView({
                behavior: 'smooth'
              });
            }, 500);
          }
        }
      })
    },
    
    resetFileSelected () {
      this.selectedFile = null;
      this.$refs.fileRef.type = 'text'
      this.$refs.fileRef.type = 'file'
    },
    startSearchKeywords () {
      this.showSearchKeywordsCostModal = false;
      if (this.selectedFile) {
        this.startSearchKeywordsWithFile();
        return;
      }
      if (this.topics.length > ALLOWED_KEYWORD_COUNT) {
        return this.$notify({
          group: 'info', type: 'error',
          text: `${ ALLOWED_KEYWORD_COUNT } keywords are allowed at max`
        })
      }

      let topics = this.topics.slice(0, 10)

      this.topicsUsedInExtrackingKeyword = !this.checkOnlyCompetitors
        ?topics
        :[]
      if (this.competitorsListInputed.length) {
        this.topicsUsedInExtrackingKeyword = [
          ...this.topicsUsedInExtrackingKeyword,
          `${this.competitorsListInputed.length} competitor url${this.competitorsListInputed.length>1?'s':''}`
        ]
      }

      const formData = {
        campaignId: this.campaign?.id || 0,
        topics: topics,
        domain: this.domain,
        locationCode: this.selectedLocation,
        languageCode: this.selectedLanguage,
        checkOnlyCompetitors: this.checkOnlyCompetitors,
        competitors: this.competitorsListInputed,
        minWordsLimit: 3,
        maxWordsLimit: 100,
      }

      this.currentStep = this.STEPS.LOADING_KEYWORD
      this.isStartingAsyncTask = true
      this.$store.dispatch('getKeywordsFromTopic', formData)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$store.commit('triggerAsyncTasksRefresh');
          this.researchReport = response.data.report
          this.selectedResearchReportId = response.data.report.id
          this.isAwaitingAsyncTaskStatus = true;
          this.isStartingAsyncTask = false
          this.$notify({
            group: 'info', type: 'success',
            text: 'Keyword research task is started!'
          })
        } else {
          this.isStartingAsyncTask = false;
          this.$notify({
            group: 'info', type: 'error',
            text: response?.data?.msg || 'Failed to create keyword research task'
          })
        }
      })
      .catch(() => {
        this.isStartingAsyncTask = false
      })
    },
    startSearchKeywordsWithFile () {
      this.isStartingAsyncTask = true

      this.extractedKeywords = []
      this.topicsUsedInExtrackingKeyword = ['User Input Excel']
      this.currentStep = this.STEPS.LOADING_KEYWORD
      this.$store.dispatch('clusteringUserKeywordsExcel', this.selectedFile)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$store.commit('triggerAsyncTasksRefresh');
          this.researchReport = response.data.report
          this.selectedResearchReportId = response.data.report.id
          this.isAwaitingAsyncTaskStatus = true;
          this.isStartingAsyncTask = false
          this.$notify({
            group: 'info', type: 'success',
            text: 'Keyword research task is started!'
          })
        } else {
          this.isStartingAsyncTask = false;
          this.$notify({
            group: 'info', type: 'error',
            text: response?.data?.msg || 'Failed to create keyword research task'
          })
        }
      })
      .catch(() => {
        this.isStartingAsyncTask = false
      })
    },
    analyzeClustersWithAI () {
  
      const postData = {
        reportId: this.researchReport.id,
        topic: this.aiTopicFieldValue
      }

      this.isStartingAsyncTask = true
      this.$store.dispatch('supportKeywordResearch_analyzeClustersWithAI', postData)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            this.researchReport = response.data.report
            this.selectedResearchReportId = response.data.report.id
            this.isAwaitingAsyncTaskStatus = true;
            this.isStartingAsyncTask = false
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg || 'Failed to start clusters analysis'
            })
            this.isStartingAsyncTask = false
          }
        }
      })
      .catch(() => {
        this.isStartingAsyncTask = false
      })
    },
    createSupportKeywordResearchAction () {
      this.showGetKeywordDataCostWarnModal = false;

      let keywords = getValidKeywords(this.finalKeywords.map(f => f.keyword))
      let postData = {
        campaignId: this.campaignId,
        reportId: this.researchReport ? this.researchReport.id : 0,
        keywords: keywords,
        locationCode: this.selectedLocation,
        languageCode: this.selectedLanguage,
        domain: this.domain,
        targetTopics: this.topicsUsedInExtrackingKeyword,
        competitorUrls: this.competitorsListInputed
      }

      this.currentStep = this.STEPS.LOADING_RESULT_FOR_KEYWORD
      this.isStartingAsyncTask = true
      this.$store.dispatch('createSupportKeywordResearch', postData)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            this.researchReport = response.data.report
            this.selectedResearchReportId = response.data.report.id
            this.isAwaitingAsyncTaskStatus = true;
            this.isStartingAsyncTask = false
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg || 'Failed to analyze keyword research data'
            })
            this.isStartingAsyncTask = false
          }
        }
      })
      .catch(() => {
        this.isStartingAsyncTask = false
      })
    },
    getAdvancedDataAction (keywords) {

      let postData = {
        campaignId: this.campaignId,
        reportId: this.researchReport.id,
        keywords: keywords,
        advanced_mode: true
      }

      this.isStartingAsyncTask = true
      this.$store.dispatch('createSupportKeywordResearch', postData)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            this.researchReport = response.data.report
            this.selectedResearchReportId = response.data.report.id
            this.isAwaitingAsyncTaskStatus = true;
            this.isStartingAsyncTask = false
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg || 'Failed to analyze keyword research data'
            })
            this.isStartingAsyncTask = false
          }
        }
      })
      .catch(() => {
        this.isStartingAsyncTask = false
      })
    },
    showClusterDetail (cName) {
      if (!this.expandedClusterNames.includes(cName)) {
        this.expandedClusterNames.push(cName)
      }
      this.$nextTick(() => {
        console.log('scrolling..')
        document.getElementById(`clusterId_${this.clusterNameToId(cName)}`).scrollIntoView({
          behavior: 'smooth'
        });
      })
    },
    onKeywordLabelUpdateSuccess(keyword, label) {
      if (label === 'T') {
        throw 'Supporting Keyword Research does not accept Top-Level Keywords'
      }
      this.researchReport = {
        ...this.researchReport,
        result: this.researchReport.result.map((row) => {
          if (row.keyword === keyword) {
            return {
              ...row,
              isSupportingKeyword: label === 'S',
            }
          }
          return row
        })
      }
    },
    handleStickyKeywordsListHeader () {
      const el = document.querySelector(".sticky-keywords-list-header");
      if (el) {
        const mainBodyTop = document.querySelector('#mainBodyScrollArea').getBoundingClientRect().top;
        const currentTop = el.getBoundingClientRect().top;
        el.classList.toggle("is-pinned", currentTop === mainBodyTop);
      }
    }
  },
  created () {
    document.querySelector('#mainBodyScrollArea').addEventListener('scroll', this.handleStickyKeywordsListHeader);
  },
  destroyed () {
    document.querySelector('#mainBodyScrollArea').removeEventListener('scroll', this.handleStickyKeywordsListHeader);
  },
  mounted () {
    if (!this.campaign) {
      this.getCampaignById()
      this.loadSupportKeywordResearchHistoryForCampaign()
    }

  },
}
</script>



<style lang="scss" scoped>
@import "../../../keyword-research.scss";

.general-info {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}

.cluster-box {
  .cluster-header {
    display: flex;
    align-items: center;
    gap: 15px;

    .expand-icon {
      cursor: pointer;
    }
    .cluster-name {
      font-weight: 700;
      font-size: 1.1rem;
      color: var(--v-mainColor-base);
    }
    .keywords-count {
      padding: 5px 10px;
      border-radius: 5px;
      background: var(--v-lightYellowColor-base);
      color: var(--v-profileAnchorColor-base);
      font-weight: 700;
    }
    .best-match-label {
      padding: 5px 10px;
      border-radius: 40px;
      background: #CDF7E3;
      color: #006843;
      font-weight: 700;
    }
  }
  .cluster-body {
    max-height: 350px;
    overflow-y: auto;
    .keywords-wrapper {
      // display: flex;
      // flex-wrap: wrap;
      // gap: 10px;
      & > div {
        margin-top: 10px;
        display: flex;
      }
      & .one-keyword-item {
        background: var(--v-gray7Color-base);
        color: var(--v-mainColor-base);
        padding: 5px 10px;
        border-radius: 50vh;
        display: flex;
        align-items: center;
        gap: 5px;
        span.close {
          color: var(--v-profileAnchorColor-base);
          cursor: pointer;
        }
      }
    }
  }
}
.remove-cluster-icon {
  color: var(--v-profileAnchorColor-base);
  cursor: pointer;
}

.keyword-data-cost-modal, .search-keywords-cost-modal {
  .message-text, .message-text * {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

</style>