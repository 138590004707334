<template>
<div style="margin-bottom: 100px;">
  <div class="d-flex align-center justify-end mt-5 mb-5 gap10" v-if="researchHistory.length > 0">
    <div class="flex-grow-1 d-flex align-center">
      <div class="card-small-title mr-5">Your results</div>
      <custom-auto-complete
        :items="researchHistory"
        :selected-value="report?.id"
        search-placeholder="Search reports"
        item-value="id"
        @updateSelectedValue="(id) => { changeResearchAction(id) }"
        @rename="renameResearchAction"
        @toggle-favorite="updateFavoriteStatus"
        @delete="deleteResearchAction"
      />
      <div
        v-if="report?.aiTaskTopic"
        class="card-small-title ml-5">AI Analysis Topic: <span class="normal-text">{{ report.aiTaskTopic }}</span></div>
    </div>
    <b-tooltip
      v-if="['KeywordResearch', 'SupportKeywordResearch', 'TopLevelSupportKeywordResearch'].indexOf(reportType) >= 0 && !hasUnlimitedAccess"
      :label="selectedKeywords.length < 1 ? 'Please select at least 1 keyword' : ''"
      type="is-dark"
    >
      <v-btn
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        :disabled="selectedKeywords.length < 1 || isStartingAIAsyncTask || isAwaitingAIAsyncTaskStatus || currentAIAsyncTask?.is_pending_or_in_progress"
        :style="{'margin-right': '10px'}"
        @click="showGetAdvancedDataModal = true">
        <i class="material-icons" style="margin-right: 10px;">query_stats</i>
        <span>Get advanced metrics</span>
      </v-btn>
    </b-tooltip>
    <b-tooltip
      v-if="['KeywordResearch', 'SupportKeywordResearch', 'TopLevelSupportKeywordResearch'].indexOf(reportType) >= 0 "
      :label="rowsWithAdvancedMetrics.length < 10 ? `Must have at least 10 keywords${!hasUnlimitedAccess?' with advanced metrics':''}` : ''"
      type="is-dark"
    >
      <v-btn
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        :disabled="rowsWithAdvancedMetrics.length < 10 || isStartingAIAsyncTask || isAwaitingAIAsyncTaskStatus || currentAIAsyncTask?.is_pending_or_in_progress || (isWhiteGloveUser && !isMissionControlSession)"
        :style="{'margin-right': '10px'}"
        @click="showAIFormModal = true">
        <i class="material-icons" style="margin-right: 10px;">auto_awesome</i>
        <span>Analyze with AI</span>
      </v-btn>
    </b-tooltip>
    <div>
      <v-btn outlined
        color="mainColor" class="text-none"
        @click="() => {
          setColumnVisibility('_searchTrend', !isColumnVisible('_searchTrend'))
        }">
        <span>{{ isColumnVisible('_searchTrend') ? 'Hide search trend' : 'Show search trend' }}</span>
      </v-btn>
    </div>
    <div>
      <div class="keyword-filter-area">
        <div>FILTERS</div>
        <div class="filter-btn" style="position:relative;" @click="() => { showFilterPopup = true; }">
          Apply filters
          <span 
            v-if="Object.keys(allAppliedFilters).length" 
            class="filter-bullet"
          ></span>
          <span class="material-icons-outlined">keyboard_arrow_down</span>

          <div class="filter-popup"
            v-if="showFilterPopup" v-click-outside="() => { showFilterPopup = false; }"
            style="position: absolute; right: 0px; top: 50px; z-index: 12;">
            <div class="filter-item-label">Keyword Score</div>
            <div class="range-wrapper">
              <v-range-slider hide-details
                :min="filterKeywordScoreMinMax[0]"
                :max="filterKeywordScoreMinMax[1]"
                v-model="filterKeywordScoreRange"
                color="profileAnchorColor"
                step="5"
                stroke="10"
                thumb-label="always"
              ></v-range-slider>
            </div>
            <div class="filter-item-label">KGR Score</div>
            <div class="range-wrapper">
              <v-range-slider hide-details
                :min="filterKGRMinMax[0]"
                :max="filterKGRMinMax[1]"
                v-model="filterKGRRange"
                color="profileAnchorColor"
                step="5"
                stroke="10"
                thumb-label="always"
              ></v-range-slider>
            </div>
            <div class="filter-item-label">Difficulty Score</div>
            <div class="range-wrapper">
              <v-range-slider hide-details
                :min="filterDifficultyScoreMinMax[0]"
                :max="filterDifficultyScoreMinMax[1]"
                v-model="filterDifficultyScoreRange"
                color="profileAnchorColor"
                step="5"
                stroke="10"
                thumb-label="always"
              ></v-range-slider>
            </div>
            <div class="d-flex align-center gap10">
              <v-btn color="profileAnchorColor"
                class="text-none whiteColor--text flex-grow-1"
                @click="applyFitlersAction">
                <span>Apply</span>
              </v-btn>
              <v-btn outlined color="mainColor"
                class="text-none"
                @click="() => {
                  updateFilterRangeReset()
                }">
                <span>Reset All</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-dropdown
        class="columns-visibility-dropdown"
        append-to-body
        aria-role="menu"
        scrollable
        max-height="200"
        trap-focus
        position="is-bottom-left"
      >
          <template #trigger>
            <div>
              <b-tooltip
                label="Show/hide columns"
                type="is-dark"
              >
                <v-btn outlined
                  color="mainColor" class="button-with-icon text-none" style="min-width: initial; padding: 0 8px;"
                >
                  <i class="material-icons-outlined" style="font-size: 1.2rem;">view_week</i>
                </v-btn>
              </b-tooltip>
            </div>
          </template>

          <b-dropdown-item custom paddingless aria-role="listitem">
            <div
              class="pb-4 mb-4"
              style="border-bottom: 2px solid #f5f5f5; font-weight: bold;"
            >{{hideableColumns.length - hiddenColumnsKeys.length}} out of {{hideableColumns.length}} selected</div>
            <div
              class="pb-4 mb-4"
              style="border-bottom: 2px solid #f5f5f5;"
            >
              <div
                v-for="hideableColumn in hideableColumns" :key="hideableColumn.key"
              >
                <v-checkbox
                  color="#0075FF"
                  dense
                  hide-details
                  :label="hideableColumn.label"
                  :input-value="isColumnVisible(hideableColumn.key)"
                  @change="(value) => setColumnVisibility(hideableColumn.key, value)"
                ></v-checkbox>
              </div>
            </div>
            
            <div class="d-flex align-center gap10">
              <v-btn text color="profileAnchorColor"
                class="text-none"
                @click="resetColumnVisibility">
                <v-icon
                  left
                  dark
                >
                  mdi-refresh
                </v-icon>
                Reset
              </v-btn>
            </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div>
      <table-pdf-csv-download
        btnStyle="min-width: initial; padding: 0 8px;"
        btnLabel="Download"
        tooltipPosition="is-left"
        :iconOnly="true"
        :tableId="tableId"
        :title="title"
        :fileName="`${title ? title.replace(/\s/g, '') : 'KeywordResearch'}_${report?.id}`"
        :columns="visibleTableColumns"
        :rows="filteredRows"
        ></table-pdf-csv-download>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div>
      <a v-if="hiddenRows.length" href="#" @click.prevent="showHiddenRowsModal = true" class="font-weight-bold">Hidden rows ({{ hiddenRows.length }})</a>
    </div>
    <div class="how-to-use-this-dashboard" @click="openHowtoUseGuide">
      <img src="/static/cap.svg" />
      <span>How to use this dashboard?</span>
    </div>
  </div>
  
  <div
    class="flex white-selected-box w550 h78 justify-space-between mt-4"
    v-if="selectedKeywords?.length"
  >
    <div class="box-primary-bg d-flex align-center">
      <div class="count-value px-5">
        {{ selectedKeywords?.length || 0 }}
      </div>
    </div>
    <div class="px-3 d-flex align-center">
      <div class="selected-text">Items selected</div>
    </div>
    <div class="d-flex flex-wrap align-center">
      <div class="flex gap15 align-center fill-height">
        <span
          v-if="!hasUnlimitedAccess"
          class="flex flex-column align-center cursor-pointer w78"
          @click="showGetAdvancedDataModal = true"
        >
          <i class="material-icons-outlined orange-color">query_stats</i>
          <div class="action-title">Advanced</div>
        </span>
        <span
          v-if="hasSiloPermission"
          class="flex flex-column align-center cursor-pointer w78"
          @click="openBulkModal()"
        >
          <i class="material-icons-outlined orange-color">post_add</i>
          <div class="action-title">Add to silo</div>
        </span>
        <span class="flex flex-column align-center cursor-pointer w78"
          @click="bulkTrackKeyword()"
        >
          <i class="material-icons orange-color">add_circle_outline</i>
          <div class="action-title">Track</div>
        </span>
        <span class="flex flex-column align-center cursor-pointer w78"
          @click="updateKeywordsVisibility(selectedKeywords.map(k => k.keyword), false)"
        >
          <i class="material-icons-outlined orange-color">visibility_off</i>
          <div class="action-title">Hide</div>
        </span>
        <div
          class="cross-container flex justify-center align-center fill-height px-5"
        >
          <i @click="resetSelectedKeywords" class="material-icons cursor-pointer">close</i>
        </div>
      </div>
    </div>
  </div>
  <v-card class="white-common-box mt-5 mb-10" style="width: 100%;">
    <horizontal-two-scroll-bar>
      <table class="keyword-result-table" v-if="report" :id="tableId">
        <thead>
          <tr>
            <th rowspan="2">
              <div>                
                <v-checkbox 
                  hide-details="auto" 
                  dense 
                  class="mt-0 pt-0" 
                  color="#007aff"
                  :value="selectedKeywords.length === filteredRows.length" 
                  @change="toggleSelectAll()"
                >
                </v-checkbox>
              </div>
            </th>
            <th rowspan="2"
              v-for="(col, idx) in COMMON_COLUMNS.filter(column => isColumnVisible(column.key))" :key="idx+'common'" @click="changeSortField(col.value)"
              :style="{
                'text-align': col.align || 'start',
              }"
            >
              <template v-if="col.tooltipText">
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs" v-on="on">
                      {{ col.label }}
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip p-3">
                    <span class="tooltip-heading">{{ col.tooltipHeading ? col.tooltipHeading : col.label }}</span><br /><br />
                    <span class="tooltip-discription">
                      {{ col.tooltipText }}
                    </span>
                  </div>
                </v-tooltip>
              </template>
              <template v-else>
                {{ col.label }}
              </template>
              <i v-if="sortField == col.value" :class="{ 'fa': true, 'fa-sort-amount-asc': sortBy == 'asc', 'fa-sort-amount-desc': sortBy == 'desc' }"></i>
            </th>
            <th
              v-if="isColumnVisible('_trafficEstimatePerMonth')"
              colspan="6"
              scope="colgroup"
            >
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs" v-on="on">
                    Traffic Estimates per Month by Rank Position
                  </span>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  These columns provide an estimate on the amount of traffic that you would receive based on ranking in Google for a particular keyword. The 1st and 2nd pages of Google search results are the most important pages as nearly all traffic comes from those two pages. The first page of Google search results constitutes rankings #1-10. The 2nd page of Google search results start at ranking position #11.
                </div>
              </v-tooltip>
            </th>
            <template v-if="isColumnVisible('_searchTrend')">
              <th colspan="13" scope="colgroup" style="text-align: center;">
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs" v-on="on">
                      Search Volume Trends
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    Shows the monthly search volume trends for a keyword, helping identify seasonal interest or fluctuating demand over time.
                  </div>
                </v-tooltip>              
              </th>
            </template>
            <th rowspan="2">Action</th>
          </tr>    
          <tr>
            <template v-if="isColumnVisible('_trafficEstimatePerMonth')">
              <th v-for="(col, idx) in MONTHLY_SEARCH_COLUMNS" :key="idx+'monthly'" @click="changeSortField(col.value)">
                {{ col.label }}
                <i v-if="sortField == col.value" :class="{ 'fa': true, 'fa-sort-amount-asc': sortBy == 'asc', 'fa-sort-amount-desc': sortBy == 'desc' }"></i>
              </th>
            </template>
            <template v-if="isColumnVisible('_searchTrend')">
              <th v-for="(col, idx) in SEARCH_TREND_COLUMNS" :key="idx+'search_trend'">{{ col.label }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, idx) in filteredRows" :key="idx" @mouseover="isCurrentIdx = idx" @mouseleave="isCurrentIdx = null">
            <td>
              <v-checkbox 
                hide-details="auto" 
                dense 
                class="mt-0 pt-0"
                color="#007aff"
                :value="selectedKeywords.includes(r)" 
                @change="toggleSelectedKeyword(r)"
              >
              </v-checkbox>
            </td>
            <td v-for="(col, j) in visibleTableColumns" :key="idx+'_'+j+'col'" :style="styleForCell( col.value, r )">
              <div 
                class="d-flex align-center gap5"
                :class="{
                  'justify-start': col.align == 'start', 'justify-center': col.align == 'center', 'justify-end': col.align == 'end',
                  'keyword-col': col.value == 'keyword'
                }"
                >
                <div v-if="col.value == 'keyword'">
                  <span v-if="trackedKeywords.includes(r.keyword)">
                    <b-tooltip
                      label="Tracked Keyword"
                      type="is-dark"
                    >
                      <span @click="() => {
                        toggleTrackUntrackKeyword(false, r)
                      }">
                        <i class="material-icons" style="color: var(--v-green7Color-base);">check_circle</i>
                      </span>
                    </b-tooltip>
                  </span>
                  <span v-else>
                    <b-tooltip
                      label="Track"
                      type="is-dark"
                    >
                      <span @click="() => {
                          toggleTrackUntrackKeyword(true, r)
                      }">
                        <i class="material-icons add-icon grayColor--text">add_circle</i>
                      </span>
                    </b-tooltip>
                  </span>
                </div>
                <div v-if="col.value == 'currentRankingUrl' && isURL(r[col.value])">
                  <a :href="r[col.value]" target='_blank' class='open-url-link'>{{ r[col.value] }}</a>
                </div>
                <template v-else-if="col.value == 'keywordType'">
                  <v-tooltip top color="transparent" v-if="r.isTopLevelKeyword">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                        <template>
                            <b-tag type="is-success is-light"><strong class="has-text-success-dark">T</strong></b-tag>
                        </template>
                      </span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip p-3">
                      <span class="tooltip-heading">Top-level keyword</span><br /><br />
                      <span class="tooltip-discription">
                        This is the main keyword your target page<br />
                        can rank for. The more difficult this keyword<br />
                        is, the more supporting posts you’ll need
                      </span>
                    </div>
                  </v-tooltip>
                  <v-tooltip top color="transparent" v-else-if="r.isSupportingKeyword">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                        <template>
                            <b-tag type="is-danger is-light"><strong class="has-text-danger">S</strong></b-tag>
                        </template>
                      </span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip p-3">
                      <span class="tooltip-heading">Supporting keyword</span><br /><br/>
                      <span class="tooltip-discription">
                        This is a keyword that you can use to<br />
                        create supporting pages and point<br />
                        their links to your main target page.
                      </span>
                    </div>
                  </v-tooltip>

                  <v-menu
                    offset-y
                    :disabled="keywordsOngoingLabelUpdate.indexOf(r.keyword) >= 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-button
                        class="keyword-label-dropdown-button"
                        :loading="keywordsOngoingLabelUpdate.indexOf(r.keyword) >= 0"
                        type="is-ghost"
                        size="is-small"
                        :icon-right="keywordsOngoingLabelUpdate.indexOf(r.keyword) >= 0 ? undefined : 'angle-down'"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-list>
                      <v-list-item v-if="reportType !== 'SupportKeywordResearch' && !r.isTopLevelKeyword" @click="updateKeywordLabel(r.keyword, 'T')">Mark as the Top-level Keyword</v-list-item>
                      <v-list-item v-if="!r.isSupportingKeyword" @click="updateKeywordLabel(r.keyword, 'S')">Mark as a Supporting Keyword</v-list-item>
                      <v-list-item v-if="r.isTopLevelKeyword || r.isSupportingKeyword" @click="updateKeywordLabel(r.keyword, null)">Unmark</v-list-item>
                    </v-list>
                  </v-menu>
                  
                </template>
                <template v-else-if="col.value == 'pageRunScore'">
                  <template v-if="r.pageRunId && !r.pageNotBuilt">
                    <span
                      class="flex align-sm-center justify-md-center cursor-pointer"
                      @click="gotoPage(`/sites/${siteId}/pages/${r.pageId}/pageruns/${r.pageRunId}`)"
                    >
                      <radial-progress-bar
                        :diameter="40"
                        :completed-steps="r.pageRunScore"
                        :total-steps="100"
                        :strokeWidth="7"
                        :startColor="pageScoreTintColor(r.pageRunScore)"
                        :stopColor="pageScoreTintColor(r.pageRunScore)"
                        :innerStrokeColor="'#D9D9D9'"
                        strokeLinecap="square"
                      />
                      <span class="ml-2">{{ r.pageRunScore }}</span>
                    </span>
                  </template>
                  <span
                    v-else-if="r.pageRunId"
                    class="cursor-pointer"
                    @click="gotoPage(`/sites/${siteId}/pages/${r.pageId}/pageruns/${r.pageRunId}`)"
                  >
                    N/A
                  </span>
                </template>
                <span v-else-if="col.value == 'keywordScore'">
                  <span
                    v-if="![undefined, null].includes(r.keywordScore)"
                    class="tag"
                    :style="{...keywordScoreToColorStyles(r.keywordScore), width: '35px'}"
                  >
                    {{ Math.round(r.keywordScore) }}
                  </span>
                  <template v-else>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="font-weight-bold" v-bind="attrs" v-on="on">-</span>
                      </template>
                      <div style="text-align: center;">Select this keyword to get<br/>all available advanced data<br/>(Keyword score, KGR score, SCI,<br/>All In Title)</div>
                    </v-tooltip>
                  </template>
                </span>
                <template v-else-if="col.value == 'KGRScore'">
                  <template v-if="![undefined, null].includes(r.keywordScore)">
                    <div>{{ r.KGRScore }}</div>
                    <span class="bullet kgr-score-bullet"
                      :class="{ 'best': r.KGRScore <=2.5, 'good': r.KGRScore > 2.5 && r.KGRScore <= 5, 'normal': r.KGRScore > 5 }">
                    </span>
                  </template>
                  <template v-else>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="font-weight-bold" v-bind="attrs" v-on="on">-</span>
                      </template>
                      <div style="text-align: center;">Select this keyword to get<br/>all available advanced data<br/>(Keyword score, KGR score, SCI,<br/>All In Title)</div>
                    </v-tooltip>
                  </template>
                </template>
                <span v-else-if="col.value == 'Search_Volumn_Chart'">
                  <apex-chart type="area" height="35" width="100" :options="searchTrendChartOptions" :series="getRowSearchTrendSeries(r)"></apex-chart>
                </span>
                <template v-else-if="col.isAdvancedMetric">
                  <div v-if="![undefined, null].includes(r[col.value])" v-html="renderCellValue(col.value, r[col.value])"></div>
                  <template v-else>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="font-weight-bold" v-bind="attrs" v-on="on">-</span>
                      </template>
                      <div style="text-align: center;">Select this keyword to get<br/>all available advanced data<br/>(Keyword score, KGR score, SCI,<br/>All In Title)</div>
                    </v-tooltip>
                  </template>
                </template>
                <template v-else>
                  <div v-html="renderCellValue(col.value, r[col.value])"></div>
                </template>

                <span  v-if="col.value == 'keyword'" :style="isCurrentIdx !== idx?'visibility:hidden;':''">
                  <b-tooltip
                    label="Hide"
                    type="is-dark"
                  >
                    <span @click="() => {
                        updateKeywordsVisibility([r['keyword']], false)
                    }">
                      <i class="material-icons">close</i>
                    </span>
                  </b-tooltip>
                </span>
              </div>
            </td>
            <td>
                <div class="flex" style="width: max-content;">
                  <span>
                    <b-tooltip
                      label="Generate POP report"
                      type="is-dark"
                    >
                      <v-btn
                        outlined
                        small
                        color="mainColor"
                        class="button-with-icon text-none mr-2"
                        @click="gotoNewPopReport(r)"
                      >
                        <i class="material-icons">speed</i>
                        <span class="ml-1">POP report</span>
                      </v-btn>
                    </b-tooltip>  
                  </span>
                  <template v-if="hasSiloPermission">
                    <div v-if="!r.isSupportingKeyword && !r.isTopLevelKeyword" class="box-wh35 mr-2 cursor-pointer">
                      <span>
                        <i class="material-icons-outlined disabled-field">post_add</i>
                      </span>
                    </div>
                    <div v-else-if="allSiloKeywords.includes(r.keyword)">
                      <v-tooltip top color="transparent">
                        <template
                          v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }"
                        >
                          <div class="box-wh35 mr-2 cursor-pointer" v-bind="tooltipAttrs" v-on="tooltipOn">
                            <v-menu :close-on-content-click="false" :nudge-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <i
                                  v-bind="attrs"
                                  v-on="on"
                                  class="success material-icons disabled-field"
                                  style="color: #25A97A !important; background-color: transparent !important;"
                                >check_circle</i>
                              </template>

                              <v-card>
                                <div
                                  class="silo-popup"
                                  style="padding: 15px; width: 21.75rem"
                                >
                                  <div>
                                    <span
                                      >You have already added this keyword to the
                                      silo.</span
                                    >
                                    <v-btn
                                      rounded
                                      color="profileAnchorColor"
                                      class="text-none whiteColor--text mt-5"
                                      @click="
                                        applyKeywordSilo(
                                          fetchSiloFromKeyword(r.keyword)?.id,
                                        )
                                      "
                                    >
                                      <span>Open Silo</span>
                                    </v-btn>
                                  </div>
                                </div>
                              </v-card>
                            </v-menu>
                          </div>
                        </template>
                        <div class="white-bg-tooltip small-tooltip p-3">
                          <span class="tooltip-discription">
                            This {{r.isTopLevelKeyword?'Top-level':(r.isSupportingKeyword?'Supporting':'')}} Keyword was added to Silo <strong id="silo-name">{{fetchSiloFromKeyword(r.keyword).name}}</strong>
                          </span>
                        </div>
                      </v-tooltip>
                    </div>
                    <v-tooltip v-else top color="transparent">
                      <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                        <span class="cursor-pointer box-wh35 mr-2">
                          <div class="box-wh35 cursor-pointer">
                            <v-menu
                              :close-on-content-click="false"
                              :nudge-width="200"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="tooltipAttrs" v-on="tooltipOn">
                                  <i
                                    v-bind="attrs"
                                    v-on="on"
                                    class="material-icons-outlined"
                                    >post_add</i
                                  >
                                </span>
                              </template>
                              <v-card>
                                <div class="silo-new-popup" style="padding: 15px">
                                  <div class="filter-item-label"></div>
                                  <div>
                                    <v-text-field
                                      outlined
                                      dense
                                      hide-details
                                      label="Search silos"
                                      prepend-inner-icon="search"
                                      class="search-field"
                                      v-model="siloSearch"
                                    >
                                    </v-text-field>
                                    <div
                                      class="flex flex-column gap5 pt-2"
                                      style="max-height: 200px; overflow-y: auto;"
                                    >
                                      <div
                                        v-for="(silo, idx) in filteredSilos"
                                        @click="addKeywordToSilo(r, silo)"
                                        :key="idx"
                                        class="flex gap5 align-center cursor-pointer"
                                      >
                                        <i
                                          v-if="silo.silo_keywords.some(keyword => keyword.keywordData.isTopLevelKeyword)"
                                          class="material-icons-outlined green-check"
                                          style="color: var(--v-green21Color-base);"
                                        >
                                          check
                                        </i>
                                        <i v-else class="material-icons-outlined green-check">
                                          check
                                        </i>
                                        <span>{{ silo.name }}</span>
                                      </div>
                                    </div>
                                    <div
                                      class="flex gap2 align-center mt-2 cursor-pointer"
                                      style="margin-left: 8px"
                                      @click="() => openSiloModal(r)"
                                    >
                                      <img
                                        src="/static/add_orange.svg"
                                        style="
                                          background-color: transparent;
                                          width: auto;
                                          height: auto;
                                          margin-left: -4px;
                                        "
                                      />
                                      <span
                                        class="primary-text"
                                        style="
                                          margin-left: 6px;
                                          color: var(--v-profileAnchorColor-base);
                                          font-size: 0.85rem;
                                          font-weight: 700;
                                        "
                                        >Create new silo</span
                                      >
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </v-card>
                            </v-menu>
                          </div>
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip p-3">
                        <span class="tooltip-heading">Add this keyword to a content Silo.</span><br />
                        <span class="tooltip-discription">
                          A Silo can contain up to 4 Top-level Keywords and up to 15 Supporting Keywords.
                        </span>
                      </div>
                    </v-tooltip>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </horizontal-two-scroll-bar>
    </v-card>
    <silo-pop-up :popUpSilo="popUpSilo" @closeModal="closeSiloModal" @addKeywordToSilo="addKeywordToSilo" />
    <silo-bulk-pop-up 
      :popUpSilo="bulkPopUp" 
      :keywords="selectedKeywords" 
      @openSiloModal="openSiloModal" 
      :silos="silos" 
      @closeModal="closeBulkModal" 
      @bulkAddKeywordToSilo="bulkAddKeywordToSilo" 
      @fetchSilos="fetchSilos"
    />
  <div class="modal" :class="{'is-active': showAIFormModal}">
    <div class="modal-background" @click.stop="showAIFormModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Analyze with AI</p>
          <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
            Type your topic title or your target keyword <br /><br />
            <v-text-field outlined dense
              v-model="aiTopicFieldValue"
              placeholder="Enter topic or target keyword"
              :error="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
              :error-messages="(aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80)?'Must be 1 to 80 characters':''"
              color="grayColor"
            ></v-text-field>
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showAIFormModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              :disabled="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
              @click.stop="() => {
                analyzeWithAI(); showAIFormModal = false
              }"
            >
              <span>Run</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showAIFormModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal advanced-keyword-data-cost-modal" :class="{'is-active': showGetAdvancedDataModal}">
    <div class="modal-background" @click.stop="showGetAdvancedDataModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Fetch advanced keyword data</p>
          <div class="mainColor--text message-text">
            <div class="mb-2">You’ve selected <span class="font-weight-bold">{{ selectedKeywords.length }} keyword{{selectedKeywords.length >1 ?'s':''}}</span> to analyze.</div>
            <div class="mb-1">Unlock <span class="font-weight-bold">advanced metrics</span> for {{selectedKeywords.length >1 ?'each':'the selected'}} keyword, including:</div>
            <ul class="mb-2" style="list-style: initial;">
              <li>Keyword Score</li>
              <li>SEO Competitiveness Index (SCI)</li>
              <li>AllinTitle</li>
              <li>KGR Score</li>
            </ul>
            
            This action requires <span class="font-weight-bold">{{ Math.ceil(selectedKeywords.length / 8) }} POP Credit{{Math.ceil(selectedKeywords.length / 8) >1 ?'s':''}}</span>. Click <span class="font-weight-bold">Get data</span> to proceed.
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showGetAdvancedDataModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              @click.stop="showGetAdvancedDataModal = false; $emit('requestedAdvancedData', selectedKeywords.map(row => row['keyword']))">
              <span>Get data</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showGetAdvancedDataModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal hidden-rows-modal" :class="{'is-active': showHiddenRowsModal}">
    <div class="modal-background" @click.stop="showHiddenRowsModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Hidden rows ({{ hiddenRows.length }})</p>
          <div class="mainColor--text message-text">
            <template v-if="hiddenRows.length">
              <div class="mt-6 mb-6">
                <a class="font-weight-bold" href="#" @click.stop.prevent="updateKeywordsVisibility(hiddenRows.map(row => row.keyword), true)">Unhide all</a>
              </div>
              <ul class="mb-2" style="list-style: initial;">
                <li v-for="row in hiddenRows" :key="row.keyword">{{ row.keyword }} (<a href="#" @click.stop.prevent="updateKeywordsVisibility([row.keyword], true)" class="font-weight-bold">unhide</a>)</li>
              </ul>
            </template>
            <template v-else>
              No hidden rows
            </template>
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showHiddenRowsModal = false;
              }">
              <span>Close</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showHiddenRowsModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <keyword-research-modal
    :showModal="showModal"
    :modalTitle="modalTitle"
    :modalType="modalType"
    :reportTitle="reportTitle"
    @cancel="cancelModal"
    @close="closeModal"
    @update-title="updateReportTitle"
  />

</div>
</template>


<script>
import { pageScoreTintColor, getPureServicePkgName } from '@/utils';
import RadialProgressBar from 'vue-radial-progress';
import CustomAutoComplete from '@/common/CustomAutoComplete.vue';
import KeywordResearchModal from '@/common/KeywordResearchModal.vue';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"

const SEARCH_COLUMN_VALUES = [
  "Search_Volumn_January",
  "Search_Volumn_February",
  "Search_Volumn_March",
  "Search_Volumn_April",
  "Search_Volumn_May",
  "Search_Volumn_June",
  "Search_Volumn_July",
  "Search_Volumn_August",
  "Search_Volumn_September",
  "Search_Volumn_October",
  "Search_Volumn_November",
  "Search_Volumn_December",
]
const COMMON_COLUMNS = [
  { key: "keyword", label: "Keyword", value: "keyword", align: 'start' },
  { 
    key: "keywordType",
    label: "Type",
    value: "keywordType",
    tooltipHeading: "Keyword Type",
    tooltipText: "This feature uses AI and our specialized algorithm to find the best main keywords for your top page and keywords to support it. It helps you build a well-structured content ‘SILO’ that makes it easier for search engines to understand your content and boost rankings. It’s all tailored to fit your content goals, so your keywords are both relevant and strategic.",
    downloadButtonValue: (row) => {
      if (row.isTopLevelKeyword) {
        return 'Top-Level Keyword'
      }
      if (row.isSupportingKeyword) {
        return 'Supporting Keyword'
      }
      return ''
    },
    align: 'end'
  },
  {
    key: "pageRunScore", label: "POP Score", value: "pageRunScore", align: 'center',
    tooltipText: "The POP Rank Engine™️ evaluates over 300 SEO factors to provide recommendations aimed at improving your on-page SEO. This score reflects the effectiveness of your page optimization based on ongoing SEO experiments.",
    isAdvancedMetric: true,
  },
  { key: "keywordScore", label: "Keyword score", value: "keywordScore", align: 'end',
    tooltipText: 'The Keyword Score indicates the value of a keyword for your SEO strategy. It combines monthly search volume, competition strength, and keyword difficulty into a single, color-coded score, with darker greens signaling better keyword opportunities.',
    isAdvancedMetric: true,
  },
  {
    key: "KGRScore", label: "KGR score", value: "KGRScore", align: 'end',
    tooltipText: "The Keyword Golden Ratio metric identifies low-competition, high-opportunity keywords by calculating the ratio of Google search results to local monthly search volume. A score below 2.5 suggests a strong chance to rank in the top 50 results.",
    isAdvancedMetric: true,
  },
  {
    key: "realCompMajesticTitleAndAnchorComp", label: "SCI", value: "realCompMajesticTitleAndAnchorComp", align: 'end',
    tooltipHeading: "SEO Competitiveness Index (SCI)",
    tooltipText: "The SEO Competitiveness Index score evaluates how competitive a keyword is based on the frequency of title optimization and the presence of relevant anchor text in backlinks. Higher SCI indicates more competitors actively optimizing for this keyword.",
    isAdvancedMetric: true,
  },
  {
    key: "allintitle", label: "All In Title", value: "allintitle", align: 'end',
    tooltipText: "This metric shows the number of pages that use the exact keyword in their title tag. A higher number suggests more competition targeting the keyword in on-page SEO.",
    isAdvancedMetric: true,
  },
  {
    key: "broadMatchMonthlySearches_LowEstimate", label: "Monthly searches low", value: "broadMatchMonthlySearches_LowEstimate", align: 'end',
    tooltipText: 'This represents the lowest estimated monthly search volume for a keyword, helping you gauge its baseline popularity.',    
  },
  {
    key: "broadMatchMonthlySearches_HighEstimate", label: "Monthly searches high", value: "broadMatchMonthlySearches_HighEstimate", align: 'end',
    tooltipText: 'This represents the highest estimated monthly search volume for a keyword, indicating peak search demand.',
  },
  {
    key: "difficultyScore", label: "Difficulty score %", value: "difficultyScore", align: 'end',
    tooltipText: 'Indicates how hard it is to rank in the top 10 search results for a keyword, on a scale of 0 to 100. Higher percentages reflect higher competition and ranking difficulty.',
  },
  {
    key: "currentRankGoogle", label: "Current rank(Google)", value: "currentRankGoogle",
    tooltipText: "Your current Google ranking position for specific keywords. Lower numbers reflect higher positions on the results page.",
  },
  {
    key: "currentRankingUrl", label: "Current ranking URL", value: "currentRankingUrl", align: 'start',
    tooltipText: 'The URL of your page currently ranking on Google for the selected keyword.',
  },
  {
    key: "competitionStrength", label: "Competition strength", value: "competitionStrength", align: 'end',
    tooltipText: 'Estimates the competitive strength of a keyword based on difficulty metrics, helping you assess the effort needed to outrank competitors.',
  },
  {
    key: "estimatedTimeToWin_in_Months", label: "Est. Months to Win", value: "estimatedTimeToWin_in_Months", align: 'end',
    tooltipText: "Reflects the anticipated duration, in months, to achieve a top 10 ranking position for a keyword. Ranges are categorized from a fast track of 2-3 months to longer efforts exceeding 12 months.",
    showOnlyAdmin: true,
  },
]
const MONTHLY_SEARCH_COLUMNS = [
  { key: "trafficEstimatePerMonthByRankPos_#1", label: "#1", value: "trafficEstimatePerMonthByRankPos_#1" },
  { key: "trafficEstimatePerMonthByRankPos_#3", label: "#3", value: "trafficEstimatePerMonthByRankPos_#3" },
  { key: "trafficEstimatePerMonthByRankPos_#5", label: "#5", value: "trafficEstimatePerMonthByRankPos_#5" },
  { key: "trafficEstimatePerMonthByRankPos_#7", label: "#7", value: "trafficEstimatePerMonthByRankPos_#7" },
  { key: "trafficEstimatePerMonthByRankPos_#10_#11", label: "#10", value: "trafficEstimatePerMonthByRankPos_#10_#11" },
  { key: "trafficEstimatePerMonthByRankPos_#12_#19", label: "#12+", value: "trafficEstimatePerMonthByRankPos_#12_#19" },
].map(col => ({ ...col, align: 'end' }))
const SEARCH_TREND_COLUMNS = [
  // SEARCH COLUMN OPTIONS
  { key: "Search_Volumn_Chart", label: "Search Trend", value: "Search_Volumn_Chart", align: 'center' },
  { key: "Search_Volumn_January", label: "January", value: "Search_Volumn_January", align: 'center' },
  { key: "Search_Volumn_February", label: "February", value: "Search_Volumn_February", align: 'center' },
  { key: "Search_Volumn_March", label: "March", value: "Search_Volumn_March", align: 'center' },
  { key: "Search_Volumn_April", label: "April", value: "Search_Volumn_April", align: 'center' },
  { key: "Search_Volumn_May", label: "May", value: "Search_Volumn_May", align: 'center' },
  { key: "Search_Volumn_June", label: "June", value: "Search_Volumn_June", align: 'center' },
  { key: "Search_Volumn_July", label: "July", value: "Search_Volumn_July", align: 'center' },
  { key: "Search_Volumn_August", label: "August", value: "Search_Volumn_August", align: 'center' },
  { key: "Search_Volumn_September", label: "September", value: "Search_Volumn_September", align: 'center' },
  { key: "Search_Volumn_October", label: "October", value: "Search_Volumn_October", align: 'center' },
  { key: "Search_Volumn_November", label: "November", value: "Search_Volumn_November", align: 'center' },
  { key: "Search_Volumn_December", label: "December", value: "Search_Volumn_December", align: 'center' },
]
const HIDDEN_COLUMNS_KEYS_DEFAULT = [
  '_searchTrend',
  'competitionStrength',
  'estimatedTimeToWin_in_Months',
  '_trafficEstimatePerMonth',
]

import TablePdfCsvDownload from '@/components/Campaigns/TablePdfCsvDownload.vue';
import { toLocalDateTimeString } from '@/utils';
import isURL from 'validator/lib/isURL';
import HorizontalTwoScrollBar from '@/common/HorizontalTwoScrollBar.vue';
import SiloPopUp from "@/components/SiloPopUp.vue";
import SiloBulkPopUp from "@/components/SiloBulkPopUp.vue";

export default {
  props: {
    researchHistory: {
      default: []
    },
    report: {
      default: null
    },
    title: {
      default: ""
    },
    reportType: {
      default: null
    },
    showSiloExtraActions: {
      default: false
    },
    hasUnlimitedAccess: {

    },
    showModal: {
      default: false
    },
    modalTitle: {
      default: ''
    },
    modalType: {
      default: 0
    },
    reportTitle: {
      default: ''
    }
  },
  components: {
    RadialProgressBar,
    TablePdfCsvDownload,
    HorizontalTwoScrollBar,
    SiloPopUp,
    SiloBulkPopUp,
    CustomAutoComplete,
    KeywordResearchModal,
  },
  watch: {
    report: function (newVal) {
      if (newVal) {
        this.updateFilterRangeReset()
        this.loadKeywordTrack()
      }
    },
    currentAIAsyncTask: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isAwaitingAIAsyncTaskStatus = false;
      }
      if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
        this.$store.commit('hideLoading');
        this.$emit('onAIResultReady')
        this.sortField = 'keywordType'
        this.sortBy = 'desc'
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    hasSiloPermission () {
      if (this.selectedSubAccountRelation) {
        if (this.isSpecialOwnerSelected) {
          return true
        } else {
          return this.selectedSubAccountRelation.grantedDevModeTools.includes('silo')
        }
      } else if (this.isSpecialAdminUser) {
        return true
      }
      return this.user.grantedDevModeTools.includes('silo')
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    filteredSilos() {
      return this.siloSearch ? this.silos.filter(silo => silo.name?.toLowerCase().includes(this.siloSearch?.toLowerCase())) : this.silos
    },
    allSiloKeywords() {
      return this.silos.flatMap(silo => silo.silo_keywords.map(siloKeyword => siloKeyword.keyword))
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    siteId () {
      return parseInt(this.$route.params.siteId || 0)
    },
    isURL () {
      return isURL
    },
    tableId () {
      if (this.report) {
        return 'research_table' + this.report.id
      } else {
        return 'empty_table'
      }
    },
    filteredRows () {
      if (!this.report) {
        return []
      }
      let rlt = this.report.result

      const {
        filterKeywordScoreRange,
        filterKGRRange,
        filterDifficultyScoreRange
      } = this.allAppliedFilters

      let sortField = this.sortField
      let sortBy = this.sortBy
      rlt = rlt.sort((a, b) => {
        let aVal = a[sortField];
        let bVal = b[sortField];
        if (sortField === 'keywordType') {
          const getKeywordTypeSortingValue = (row) => {
            if (row.isTopLevelKeyword) return 3
            if (row.isSupportingKeyword) return 2
            return 1
          }
          aVal = getKeywordTypeSortingValue(a);
          bVal = getKeywordTypeSortingValue(b);
        }

        // for any equal values, default to sorting by BMMS High
        if (aVal === bVal) {
          aVal = a['broadMatchMonthlySearches_HighEstimate'];
          bVal = b['broadMatchMonthlySearches_HighEstimate'];
        }

        if (sortBy == 'asc') {
          if (aVal > bVal) return 1;
          if (aVal < bVal) return -1;
        } else {
          if (aVal > bVal) return -1;
          if (aVal < bVal) return 1;
        }
        return 0;
      }).filter(r => {
        if (r['isHidden']) {
          return false;
        }

        if (filterKeywordScoreRange) {
          let [ minVal, maxVal ] = filterKeywordScoreRange
          if ( r['keywordScore'] < minVal || r['keywordScore'] > maxVal ) {
            return false;
          }
        }
        if (filterKGRRange) {
          let [ minVal, maxVal ] = filterKGRRange
          if (r['KGRScore'] < minVal || r['KGRScore'] > maxVal ) {
            return false;
          }
        }
        if (filterDifficultyScoreRange) {
          let [ minVal, maxVal ] = filterDifficultyScoreRange
          if (r['difficultyScore'] < minVal || r['difficultyScore'] > maxVal) {
            return false;
          }
        }

        return true;
      }).map(r => {
        const updateRates = this.getSearchVolumeUpdate(r.searchVolumeFor24Month || [])
        let monthlyUpdate = {}
        updateRates.map(u => {
          monthlyUpdate[SEARCH_COLUMN_VALUES[u.month - 1]] = u.updateRate ? (u.updateRate.toFixed(2)) : '-'
        })
        return {
          ...r,
          ...monthlyUpdate
        }
      })
      return rlt;
    },
    rowsWithAdvancedMetrics () {
      return this.report?.result?.filter(row => row.hasAdvancedMetrics) || []
    },
    hiddenRows () {
      return this.report?.result?.filter(row => row.isHidden) || []
    },
    hiddenColumnsKeys () {
      const globalValue = this?.user?.settings?.keywordResearchResultTableHiddenColumnsKeysPerSite?.[this.siteId]
      if (globalValue === null || globalValue === undefined) {
        return HIDDEN_COLUMNS_KEYS_DEFAULT
      }
      return globalValue
    },
    hideableColumns () {
      return [
        // all columns
        ...this.tableColumns.filter((column) => [
          // except
          'keyword',
          'keywordType',
          ...MONTHLY_SEARCH_COLUMNS.map(c => c.key),
          ...SEARCH_TREND_COLUMNS.map(c => c.key)
        ].indexOf(column.key) < 0),

        // plus these special groupped columns
        ...this.isAdmin?[{ key: "_trafficEstimatePerMonth", label: "Traffic Estimates per Month by Rank Position"}]:[],  // special key to hide the whole group of MONTHLY_SEARCH_COLUMNS 
        { key: "_searchTrend", label: "Search Volume Trends"}  // special key to hide the whole group of SEARCH_TREND_COLUMNS 
      ]
    },
    visibleTableColumns () {
      return this.tableColumns.filter(column => {
        return this.isColumnVisible(column.key)
      })
    },
    asyncTasks() {
      return this.$store.state.asyncTasks
    },
    currentAIAsyncTask() {
      return this.asyncTasks.filter(task => task.id === this.report?.aiTaskId)[0]
    },
  },
  data () {
    return {
      silos: [],
      siloSearch: '',
      sortField: 'keywordType',
      sortBy: 'desc', // asc/desc

      showFilterPopup: false,
      showSiloPopup: false,
      filterKeywordScoreMinMax: [0, 100],
      filterKGRMinMax: [0, 100],
      filterDifficultyScoreMinMax: [0, 100],
      filterKeywordScoreRange: [0, 100],
      filterKGRRange: [0, 100],
      filterDifficultyScoreRange: [0, 100],

      allAppliedFilters: {},

      keywordsOngoingLabelUpdate: [],

      COMMON_COLUMNS: COMMON_COLUMNS,
      MONTHLY_SEARCH_COLUMNS: MONTHLY_SEARCH_COLUMNS,
      SEARCH_TREND_COLUMNS: SEARCH_TREND_COLUMNS,
      tableColumns: [],
      popUpSilo: null,
      trackedKeywords: [],

      showAIFormModal: false,
      aiTopicFieldValue: '',
      isStartingAIAsyncTask: false,
      isAwaitingAIAsyncTaskStatus: false,

      isCurrentIdx: null,
      selectedKeywords: [],
      bulkPopUp: false,

      showGetAdvancedDataModal: false,

      showHiddenRowsModal: false,

      searchTrendChartOptions: {
        chart: {
          type: 'line',
          width: 100,
          height: 35,
          sparkline: {
            enabled: true
          }
        },
        xaxis: {
          categories: SEARCH_TREND_COLUMNS.filter(col => col.value !== 'Search_Volumn_Chart').map(col => col.label),
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          numeric: true,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let title = w.globals.tooltip.tooltipTitle.outerHTML;
            let items = "";
            w.globals.tooltip.ttItems.forEach(x => {
                // update search trend tooltip color based on value
                if ( series[seriesIndex][dataPointIndex] > 0) {
                  x.style.color = '#007bff'
                } else {
                  x.style.color = '#dc3545'
                }
                items = items + x.outerHTML
            })
            return title + items;
          },
          fixed: {
            enabled: false
          },
          x: {
            show: true
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return `${value} %`
            },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          },
          marker: {
            show: false
          }
        }
      },
    }
  },
  methods: {
    getRowSearchTrendSeries(row) {
      return [{
          name: "Search Trend",
          data: SEARCH_TREND_COLUMNS
            .filter(col => col.value !== 'Search_Volumn_Chart')
            .map(col => row[col.value])
            .map(value => {
              if ([null, undefined, '-'].includes(value)) return 0; 
              return Number(value);
            })
      }]
    },
    cancelModal() {
      this.$emit('cancel');
    },
    closeModal() {
      this.$emit('close');
    },
    openHowtoUseGuide() {
      window.open('https://help.pageoptimizer.pro/article/basic-and-advanced-metrics-in-the-keyword-insight-tool', '_blank')
    },
    updateReportTitle(value) {
      this.$emit('update-title', value);
    },
    gotoPage(url) {
      this.$router.push(url)
    },
    pageScoreTintColor(score) {
      return pageScoreTintColor(score)
    },
    gotoNewPopReport(keywordReport) {
      const { siteId } = this.$route.params;
      const externalData = {
        keyword: keywordReport.keyword,
        lang: this.report.languageCode,
        loc: this.report.locationCode,
        isPageNotBuilt: !keywordReport.currentRankingUrl,
        url: keywordReport.currentRankingUrl,
        type: this.reportType,
        id: this.report.id,
      };

      this.$router.push({
        name: 'NewPage',
        params: {
          siteId,
          externalData,
        }
      });
    },
    fetchSiloFromKeyword(keyword) {
      return this.silos.find(silo => silo.silo_keywords.some(siloKeyword => siloKeyword.keyword === keyword))
    },
    isTrackedKeyword(keyword) {
      return this.trackedKeywords.some(t => t.keyword === keyword)
    },
    addKeywordToSilo(row, silo, closeModal) {
      if (!row.isTopLevelKeyword && !row.isSupportingKeyword) return;

      const topLevelKeywordsCount = silo.silo_keywords.filter(keyword => keyword.keywordData.isTopLevelKeyword)?.length || 0;
      const supportingKeywordsCount = silo.silo_keywords.filter(keyword => keyword.keywordData.isSupportingKeyword)?.length || 0;

      if (row.isTopLevelKeyword && topLevelKeywordsCount >= 4){
        if (closeModal) this.closeSiloModal(true);
        return;
      }
      
      if (row.isSupportingKeyword && supportingKeywordsCount >= 15){
        if (closeModal) this.closeSiloModal(true);
        return;
      }

      this.$store.commit('showLoading');

      this.$store
        .dispatch('addKeywordToSilo', {
          siloId: silo.id,
          body: {
            keyword: row.keyword,
            keywordData: {
              ...row,
              locationCode: this.report.locationCode,
              languageCode: this.report.languageCode,
            },
            sourceReportType: this.reportType,
            sourceReportId: this.report.id,
          },
        })
        .then((response) => {
          const siloName = response.data.siloName
          this.$store.commit('setIsNewSiloUpdates', true);
          this.$notify({
            group: 'info', type: 'success',
            text: `Successfully added keywords to ${siloName} silo.`
          })
          this.fetchSilos();
        })
        .catch(() => this.$notify({
          group: 'info', type: 'danger',
          text: 'Failed to add keyword to silo.'
        }))
        .finally(() => this.$store.commit('hideLoading'));
    },
    bulkAddKeywordToSilo(siloId, closeModal, existingSilo) {
      if (existingSilo) {
        const silo = this.silos.find(silo => silo.id == siloId)
        const topLevelKeywordsCount = silo?.silo_keywords.filter(keyword => keyword.keywordData.isTopLevelKeyword)?.length || 0;
        const supportingKeywordsCount = silo?.silo_keywords.filter(keyword => keyword.keywordData.isSupportingKeyword)?.length || 0;

        const selectedTopLevelKeywordsCount = this.selectedKeywords.filter(keyword => keyword.isTopLevelKeyword)?.length || 0;
        const selectedSupportingKeywordsCount = this.selectedKeywords.filter(keyword => keyword.isSupportingKeyword)?.length || 0;
  
        if (selectedTopLevelKeywordsCount && topLevelKeywordsCount >= 4){
          this.$notify({
            group: 'info', type: 'warning',
            text: 'Top level keywords limit reached.'
          })
          if (closeModal) this.closeBulkModal();
          return;
        }

        if (selectedSupportingKeywordsCount && supportingKeywordsCount >= 15){
          this.$notify({
            group: 'info', type: 'warning',
            text: 'Supporting keywords limit reached.'
          })
          if (closeModal) this.closeBulkModal();
          return;
        }
      }

      this.$store.commit('showLoading');

      this.$store
        .dispatch('addKeywordsToSilo', {
          siloId: siloId,
          body: {
            keywords: this.selectedKeywords,
            locationCode: this.report.locationCode,
            languageCode: this.report.languageCode,
            sourceReportType: this.reportType,
            sourceReportId: this.report.id,
          },
        })
        .then((response) => {
          const siloName = response.data.siloName
          this.$store.commit('setIsNewSiloUpdates', true);
          this.$notify({
            group: 'info', type: 'success',
            text: `Keywords added to ${siloName} silo.`
          })
          this.fetchSilos();
        })
        .catch(() => this.$notify({
          group: 'info', type: 'danger',
          text: 'Failed to add keywords to silo.'
        }))
        .finally(
          () => {
            this.$store.commit('hideLoading');
            this.closeBulkModal();
            this.selectedKeywords = [];
          }
        );
    },
    closeSiloModal(refreshData = false) {
      this.popUpSilo = null;
      if (refreshData) this.fetchSilos();
    },
    openSiloModal(row) {
      this.popUpSilo = row;
    },
    closeBulkModal() {
      this.bulkPopUp = false;
    },
    openBulkModal() {
      // Check if Selected Keywords is empty
      if (this.selectedKeywords.length === 0) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Please select keywords to add to silo.'
        })
        return;
      }
      // Check if selected keywords are marked as top level or supporting
      const unmarkedKeywordsCount = this.selectedKeywords.filter(keyword => 
        !keyword.isTopLevelKeyword && !keyword.isSupportingKeyword
      ).length;
      if (unmarkedKeywordsCount) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Some of the selected keywords are not marked as Top Level or Supporting Level.'
        })
        return;
      }
      // Check if selected keywords are already in silo
      const selectedKeywordsSet = new Set(this.selectedKeywords.map(keyword => keyword.keyword));
      if (this.silos.some(silo => silo.silo_keywords.some(keyword => selectedKeywordsSet.has(keyword.keyword)))) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Some selected keywords are already in silo.'
        })
        return;
      }
      this.bulkPopUp = true;
    },
    applyKeywordSilo(siloId) {
      if (!siloId) return

      const { siteId, campaignId } = this.$route.params
      this.$router.push(`/sites/${siteId}/campaign/${campaignId}/silo?id=${siloId}`)
    },
    applyFitlersAction() {
      this.allAppliedFilters = {
        filterKeywordScoreRange: this.filterKeywordScoreRange,
        filterKGRRange: this.filterKGRRange,
        filterDifficultyScoreRange: this.filterDifficultyScoreRange,
      }
    },
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    changeSortField (field) {
      if (field === this.sortField) {
        if (this.sortBy === "desc") {
          this.sortBy = 'asc';
        } else {
          this.sortBy = 'desc';
        }
      } else {
        this.sortField = field;
      }
    },
    renderCellValue (key, value) {
      let prefix = '';
      let suffix = '';

      switch (key) {
        case 'difficultyScore':
          suffix = '%';
          break;
        case 'cost':
          prefix = '$';
          break;
        default:
          break;
      }
      if (SEARCH_TREND_COLUMNS.map(i => i.value).includes(key) && value != '-') {
        suffix = '%';
      }
      const printValue = value === undefined ? '': value
      return (prefix + printValue + suffix)
    },
    styleForCell (key, rowData) {
      let color = ''
      const value = rowData[key]
      switch (key) {
        case 'keyword':
          if (rowData['monthlySearch'] <= 10) { // if we didn't find monthly search default value is 10;
            // color = '#EB5757'
          }
          break;
        case 'keywordScore':
          if (value > 90) {
            color = '#12814C'
          } else if (value > 58) {
            color = '#12814C'
          }
          break;
        // case 'KGRScore':
        //   if (value <= 2.5) {
        //     color = '#00CD49'
        //   } else if (value > 2.5 && value <= 5) {
        //     color = '#FF9B2D'
        //   } else {
        //     color = '#FF1F2D'
        //   }
        //   break;
        default:
          break;
      }

      if (SEARCH_TREND_COLUMNS.filter(col => col.value !== 'Search_Volumn_Chart').map(i => i.value).includes(key)) {
        if (value > 0) {
          color = '#007bff'
        } else {
          color = '#dc3545'
        }
      }
      let style = {}
      if (color) {
        style['color'] = color;
      }
      return style;
    },
    keywordScoreToColorStyles (keywordScore) {
      if (keywordScore > 90)
        return { 'background-color': '#006B3E', 'color': '#FFFFFF' }
      else if (keywordScore > 80 && keywordScore <= 90)
        return { 'background-color': '#009254', 'color': '#FFFFFF' }
      else if (keywordScore > 70 && keywordScore <= 80)
        return { 'background-color': '#49BA8B', 'color': '#FFFFFF' }
      else if (keywordScore > 65 && keywordScore <= 70)
        return { 'background-color': '#65DDAB', 'color': '#014751' }
      else if (keywordScore > 60 && keywordScore <= 65)
        return { 'background-color': '#A6EFD1', 'color': '#014751' }
      else if (keywordScore > 50 && keywordScore <= 60)
        return { 'background-color': '#DAFFF0', 'color': '#014751' }
      else
        return { 'color': '#014751' }
    },
    getMonthUpdate (month, search_volumes) {
      try {
        if (search_volumes.length < 1) {
            return null
        }
        let month_search_volumes = search_volumes.filter(s => s.month == month)

        if (month_search_volumes.length < 1) {
            return null
        }
        let avg_search_volume = month_search_volumes.map(s => s.search_volume).reduce((a, b) => a + b) / month_search_volumes.length
        let recent_year = month_search_volumes.map(m => m.year).sort().reverse()[0]
        let recent_year_search_volume = month_search_volumes.filter(m => m.year == recent_year)[0].search_volume
        if (recent_year_search_volume < 1) {
            return null
        }
        const result = (avg_search_volume / recent_year_search_volume - 1) * 100
        return result
      } catch (e) {
        return 12345678
      }
    },
    getSearchVolumeUpdate (searchVolumes) {
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      let results = []
      for (let index = 0; index < months.length; index++) {
          const m = months[index];
          const updateRate = this.getMonthUpdate(m, searchVolumes)
          results.push({
              month: m,
              updateRate: updateRate
          })
      }
      return results
    },
    changeResearchAction (id) {
      this.$emit('onChangeResearchId', id)
    },
    renameResearchAction (name, id) {
      this.$emit('rename', name, id)
    },
    updateFavoriteStatus (item) {
      this.$emit('toggle-favorite', item)
    },
    deleteResearchAction (id) {
      this.$emit('delete', id)
    },
    updateFilterRangeReset () {
      if (this.report) {
        let result = this.report.result;
        const keywordScoreList = result.map(r => r['keywordScore']).filter(r => r != undefined)
        const KGRScoreList = result.map(r => r['KGRScore']).filter(r => r != undefined)
        const difficultyScoreList = result.map(r => r['difficultyScore']).filter(r => r != undefined)
        
        const keywordScoreMin = Math.floor(Math.min(...keywordScoreList))
        const keywordScoreMax = Math.ceil(Math.max(...keywordScoreList))
        const KGRScoreMin = Math.floor(Math.min(...KGRScoreList))
        const KGRScoreMax = Math.ceil(Math.max(...KGRScoreList))
        const difficultyScoreMin = Math.floor(Math.min(...difficultyScoreList))
        const difficultyScoreMax = Math.ceil(Math.max(...difficultyScoreList))
        
        this.filterKeywordScoreMinMax = [ keywordScoreMin, keywordScoreMax ]
        this.filterKGRMinMax = [ KGRScoreMin, KGRScoreMax ]
        this.filterDifficultyScoreMinMax = [difficultyScoreMin, difficultyScoreMax]

        this.filterKeywordScoreRange = [ keywordScoreMin, keywordScoreMax ]
        this.filterKGRRange = [ KGRScoreMin, KGRScoreMax ]
        this.filterDifficultyScoreRange = [difficultyScoreMin, difficultyScoreMax]

        this.allAppliedFilters = {}
      }

    },
    toggleSelectedKeyword (r) {
      if (this.selectedKeywords.includes(r)) {
        this.selectedKeywords = this.selectedKeywords.filter(k => k !== r)
      } else {
        this.selectedKeywords = [...this.selectedKeywords, r]
      }
    },
    toggleSelectAll () {
      if (this.selectedKeywords.length === this.filteredRows.length) {
        this.selectedKeywords = []
      } else {
        this.selectedKeywords = [...this.filteredRows]
      }
    },
    resetSelectedKeywords() {
      this.selectedKeywords = []
    },
    toggleTrackUntrackKeyword (shouldAddToTrack, item) {
      let data = {}
      if (shouldAddToTrack) {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
          trackData: item,
          languageCode: this.report.languageCode,
          locationCode: this.report.locationCode,
          domain: this.report.domain
        }
        this.$store.commit('showLoading');
        this.$store.dispatch('addKeywordToTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$store.commit('setIsNewTrackingUpdates', true);
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully added keyword to track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: response.data.msg || 'Failed to add keyword to the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      } else {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
        }

        this.$store.commit('showLoading');
        this.$store.dispatch('removeKeywordFromTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully removed keyword from track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'Failed to remove keyword from the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      }
    },
    bulkTrackKeyword () {
      let data = {
        campaignId: this.campaignId,
        trackKeywords: this.selectedKeywords,
        languageCode: this.report.languageCode,
        locationCode: this.report.locationCode,
        domain: this.report.domain
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('bulkTrackKeywordToTrack', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          response.data.added && this.$store.commit('setIsNewTrackingUpdates', true);
          this.loadKeywordTrack()
          this.$notify({
            group: 'info', type: 'success',
            text: response.data.added ? 'POP is now tracking these keywords.' : 'Keywords are removed from tracking.'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to update keywords.'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
        this.selectedKeywords = []
      })
    },
    updateKeywordsVisibility(keywords, visible) {
      let data = {
        researchClass: this.reportType,
        researchId: this.report.id,
        keywords,
        visible
      }
      this.$store.commit('showLoading');
      this.$store.dispatch(
        'updateKeywordsVisibility',
        data
      )
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.selectedKeywords = this.selectedKeywords.filter(k => !keywords.includes(k.keyword))
          this.$emit('refreshReport')
          this.$notify({
            group: 'info', type: 'success',
            text: `Successfully ${visible ? 'unhidden' : 'hidden'} keyword${keywords.length > 1 ? 's' : ''}.`
          })
        }  else {
          this.$notify({
            group: 'info', type: 'error',
            text: `Failed to ${visible ? 'unhide' : 'hide'} keyword${keywords.length > 1 ? 's' : ''}.`
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    fetchSilos() {
      this.$store.dispatch('getSilos', {
        campaignId: this.$route.params.campaignId,
        paginate: 0,
      }).then(response => {
        this.silos = response.data.data;
      })
    },
    loadKeywordTrack () {
      this.$store.dispatch('getKeywordTrackForCampaign', this.campaignId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.trackedKeywords = response.data.keywordTrack.trackKeywords
        }
      })
    },
    analyzeWithAI() {
      const formdata = {
        reportId: this.report.id,
        reportType: this.reportType,
        topic: this.aiTopicFieldValue
      }
      this.isStartingAIAsyncTask = true
      this.$store.dispatch('keywordResearchAnalyzeWithAI', formdata)
      .then(response => {
        if (response?.data?.status === 'SUCCESS') {
          this.$emit('refreshReport');
          this.$store.commit('triggerAsyncTasksRefresh');
          this.isAwaitingAIAsyncTaskStatus = true;
          this.isStartingAIAsyncTask = false
        } else {
          return Promise.reject()
        }
      })
      .catch(() => {
        this.isStartingAIAsyncTask = false
      })
    },
    updateKeywordLabel(keyword, label) {
      const formdata = {
        reportId: this.report.id,
        reportType: this.reportType,
        keyword: keyword,
        label: label
      }
      this.keywordsOngoingLabelUpdate = [...this.keywordsOngoingLabelUpdate, keyword]
      this.$store.dispatch('updateKeywordLabel', formdata)
      .then(response => {
        if (response?.data?.status === 'SUCCESS') {
          this.$emit('onKeywordLabelUpdateSuccess', keyword, label)
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      })
      .finally(() => {
        this.keywordsOngoingLabelUpdate = this.keywordsOngoingLabelUpdate.filter(k => k !== keyword)
        this.selectedKeywords = [];
      })
    },
    setColumnVisibility(targetKey, visible) {
      const updatedValue = this.hiddenColumnsKeys.filter(key => (
        key !== targetKey
      ))
      if (!visible) {
        updatedValue.push(targetKey)
      }

      const updatedUserSettings = {
        ...this.user.settings,
        keywordResearchResultTableHiddenColumnsKeysPerSite: {
          ...this.user.settings.keywordResearchResultTableHiddenColumnsKeysPerSite || {},
          [this.siteId]: updatedValue
        }
      }

      // eager update of settings state
      this.$store.commit('setUserSettings', updatedUserSettings)

      // persist in backend
      this.$store.dispatch('updateUserSettings', updatedUserSettings)
    },
    resetColumnVisibility() {
      const updatedUserSettings = {
        ...this.user.settings,
        keywordResearchResultTableHiddenColumnsKeysPerSite: {
          ...this.user.settings.keywordResearchResultTableHiddenColumnsKeysPerSite || {},
          [this.siteId]: HIDDEN_COLUMNS_KEYS_DEFAULT
        }
      }

      // eager update of settings state
      this.$store.commit('setUserSettings', updatedUserSettings)

      // persist in backend
      this.$store.dispatch('updateUserSettings', updatedUserSettings)
    },
    isColumnVisible(targetKey) {
      if (targetKey === 'keywordType') {
        return this.report.aiTaskCompleted
      }
      if (MONTHLY_SEARCH_COLUMNS.map(c => c.key).indexOf(targetKey) >= 0) {
        return this.hiddenColumnsKeys.indexOf('_trafficEstimatePerMonth') < 0
      }
      if (SEARCH_TREND_COLUMNS.map(c => c.key).indexOf(targetKey) >= 0) {
        return this.hiddenColumnsKeys.indexOf('_searchTrend') < 0
      }
      return this.hiddenColumnsKeys.indexOf(targetKey) < 0
    },
  },
  mounted () {
    this.fetchSilos()
    if (this.report) {
      this.updateFilterRangeReset()
      this.loadKeywordTrack()
    }
    if (!this.isAdmin) {
      this.COMMON_COLUMNS = COMMON_COLUMNS.filter(item => !item.showOnlyAdmin)
    }
    this.tableColumns = [...this.COMMON_COLUMNS, ...this.isAdmin?MONTHLY_SEARCH_COLUMNS:[], ...SEARCH_TREND_COLUMNS]
  },
}
</script>

<style lang="scss" scoped>
@import "./keyword-research.scss";
</style>

<style lang="scss">

.columns-visibility-dropdown {
  .dropdown-menu {
    padding-top: 17px;
  }
  .dropdown-content {
    border-radius: 5px;
    padding: 15px;
    width: 300px;
    border: 1px solid var(--v-grayColor-base);
    background: var(--v-whiteColor-base);
    box-shadow: 0px 2.563px 20.825px 0px rgba(0, 0, 0, 0.12);
  }
}

#silo-name{
  color: var(--v-profileAnchorColor-base)
}

.advanced-keyword-data-cost-modal {
  .message-text, .message-text * {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.hidden-rows-modal {
  .message-text, .message-text * {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.filter-bullet {
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  top: 3px;
  right: 22px;
  background: var(--v-redColor-base);
}
</style>
